import { useState } from 'react'
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined'
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined'

import { PdfStubIcon, UrlIcon } from 'src/assets/icons'

import {
	audioFormatRegexp,
	audioRegexp,
	pdfFormatRegexp,
	pdfRegexp,
	videoFormatRegexp,
	videoRegexp,
} from 'src/utils'

interface IProps {
	type: string
	className?: string
	alt: string
	link: string
}

export const MediaRender = ({ type, className, alt, link }: IProps) => {
	const [failed, setFailed] = useState(false)

	const handleImageError = () => {
		setFailed(true)
	}

	const handleImageLoad = () => {
		setFailed(false)
	}

	const isPdf = pdfRegexp.test(type) || pdfFormatRegexp.test(link)
	const isAudio = audioRegexp.test(type) || audioFormatRegexp.test(link)
	const isVideo = videoRegexp.test(type) || videoFormatRegexp.test(link)

	if (isPdf) {
		return <PdfStubIcon className={className} />
	}

	if (isAudio) {
		return <AudioFileOutlinedIcon className={className} />
	}

	if (isVideo) {
		return <VideoFileOutlinedIcon className={className} />
	}

	if (failed) {
		return <UrlIcon className={className} />
	}

	return (
		<img
			alt={alt}
			src={link}
			onError={handleImageError}
			onLoad={handleImageLoad}
			className={className}
		/>
	)
}
