import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { mapViewSt } from '../../components/map-view/map-view.styled'
import { ImageCarousel, StepInfo } from 'src/components'
import { CollapsedItem } from './collapsed-item.component'
import { HashIcon, ImageIcon, LinkIcon, LocationIcon, TimeIcon, UserIcon } from 'src/assets/icons'
import { IMedia, IScanner } from 'src/types'
import { getPolygonscanTxUrl } from 'src/utils'
import { fitMapWithinMarkers } from 'src/utils'
import { CollapsedListSt, HashSt, TimestampSub, TimestampTitle } from './transaction-details.styled'
import { useMap } from '../../../src/contexts/journey-map.context'
import { useRef } from 'react'
import MapMarkerDark from 'src/assets/files/map-marker-dark.svg'

interface IProps {
  media: IMedia[]
  timestamp: string
  stepLocation: string
  latitude: number
  longitude: number
  hash: string
  isLast: boolean
  scanner: IScanner
}
export const CollapsedList = (props: IProps) => {
  const [t] = useTranslation()

  const { setMapRef, setMapCenter } = useMap();
  const mapRef = useRef<google.maps.Map | null>(null);

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    setMapRef(map);
      setMapCenter(props.latitude,props.longitude, 4);
    fitMapWithinMarkers(map, [{latitude: props.latitude, longitude: props.longitude}]);
  };

  return (
    <CollapsedListSt>
      <CollapsedItem
        icon={<TimeIcon />}
        title={t('timestamp-label')}
        subtitle={
          <>
            <TimestampTitle>{format(new Date(props.timestamp), 'dd LLLL yyyy')}</TimestampTitle>
            <TimestampSub>{format(new Date(props.timestamp), 'HH:mm:ss (zzz)')}</TimestampSub>
          </>
        }
      />
      {props.scanner && (
        <CollapsedItem
          icon={<UserIcon />}
          title={t('sender-label')}
          subtitle={
            <StepInfo
              name={props.scanner.name || t('scanner-header-title')}
              image={props.scanner.image_small || props.scanner.image}
              location_name={props.stepLocation}
            />
          }
        />
      )}
      <CollapsedItem
        icon={<LocationIcon />}
        title={t('location-label')}
        subtitle={
          <div style={{ width: '100%', minHeight: 220 }}>
                <GoogleMap 
                  onLoad={handleLoad}
                  mapContainerClassName={mapViewSt}
                  zoom={6}
                  options={{
                    clickableIcons: false,
                    streetViewControl: false,
                    zoomControl: false,
                    mapTypeControl: false,
                    fullscreenControl: true,
                    disableDefaultUI: true,
                    mapTypeId: 'roadmap',
                    fullscreenControlOptions: {
                      position: google.maps.ControlPosition.RIGHT_BOTTOM,
                    },
                  }}        
                >
                  <MarkerF
                    icon={MapMarkerDark}
                    position={{
                      lat: props.latitude,
                      lng: props.longitude,
                    }}
                  />
                </GoogleMap>
          </div>
        }
      />
      {props.media && Boolean(props.media.length) && (
        <CollapsedItem
          icon={<ImageIcon />}
          title={t('upl-image-label')}
          subtitle={<ImageCarousel images={props.media} />}
        />
      )}
      <CollapsedItem
        icon={<HashIcon />}
        title={t('tx-id-label')}
        subtitle={
          <HashSt href={getPolygonscanTxUrl(props.hash)} target="_blank" rel="noopener noreferrer">
            {props.hash} <LinkIcon />
          </HashSt>
        }
      />
    </CollapsedListSt>
  )
}
