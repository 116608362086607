import { styled } from '@mui/material'

export const PageWrapperSt = styled('div')<{ isJourney?: boolean }>`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  ${(props) =>
    props.isJourney &&
    'min-height: 100%; display: flex; flex-direction: column; justify-content: start;'}
`
