import { Typography, styled } from '@mui/material'

export const PageWrapperInner = styled('div')`
  max-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  overflow: hidden;
`

export const TitleSt = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
`

export const InfoWrapper = styled('div')`
  width: 100%;

  margin-bottom: ${({ theme }) => theme.spacing(3.5)};
`
