import { useTranslation } from 'react-i18next'

import { TitleWrapper } from './journey-card.styled'
import { TwitterShare } from '../share-button'
import { Typography } from '@mui/material'

interface IProps {
  journeyName: string
}

export const Title = ({ journeyName }: IProps) => {
  const [t] = useTranslation()

  return (
    <TitleWrapper>
      <Typography variant="h1">{t('journey-header-title')}</Typography>
      <TwitterShare title={journeyName} />
    </TitleWrapper>
  )
}
