import { BASE_API_V2_URL } from 'src/constants'

import { IHttpClient, IHttpConfig, IMap, IResponseData } from 'src/types'

export class HttpService implements IHttpClient {
  constructor(
    private fetchingService: IHttpClient,
    private baseUrl: string = BASE_API_V2_URL,
  ) {}

  createQueryLink(base: string, args: IMap) {
    let url = `${base}?`
    Object.keys(args).forEach((parameter, index) => {
      if (args[parameter]) {
        url += `${index > 0 ? '&' : ''}${parameter}=${args[parameter]}`
      }
    })
    return url
  }

  get<T>(url: string, config?: IHttpConfig): Promise<T | null> {
    return this.fetchingService
      .get<T>(this.getFullApiUrl(url), {
        ...config,
        headers: this.enhanceHeaders(config?.headers || {}),
      })
      .then((result) => {
        if (result) {
          this.checkResponseStatus(result.data)
          return result.data
        }
      })
      .catch(() => {
        return null
      })
  }

  post<T, D>(url: string, data: D, config?: IHttpConfig): Promise<T | null> {
    return this.fetchingService
      .post<T, D>(this.getFullApiUrl(url), data, {
        ...config,
        headers: this.enhanceHeaders(config?.headers || {}),
      })
      .then((result) => {
        if (result) {
          this.checkResponseStatus(result.data)
          return result.data
        }
      })
      .catch(() => {
        return null
      })
  }

  put<T, D>(url: string, data: D, config?: IHttpConfig): Promise<T | null> {
    return this.fetchingService
      .put<T, D>(this.getFullApiUrl(url), data, {
        ...config,
        headers: this.enhanceHeaders(config?.headers || {}),
      })
      .then((result) => {
        if (result) {
          this.checkResponseStatus(result.data)
          return result.data
        }
      })
      .catch(() => {
        return null
      })
  }

  delete<T>(url: string, config?: IHttpConfig): Promise<T | null> {
    return this.fetchingService
      .delete<T>(this.getFullApiUrl(url), {
        ...config,
        headers: this.enhanceHeaders(config?.headers || {}),
      })
      .then((result) => {
        if (result) {
          this.checkResponseStatus(result)
          return result.data
        }
      })
      .catch(() => {
        return null
      })
  }

  private enhanceHeaders(headers: IMap) {
    return {
      ['X-API-Key']: process.env.REACT_APP_X_API_KEY,
      ...headers,
    }
  }

  private getFullApiUrl(url: string) {
    return `${this.baseUrl}${url}`
  }

  // private async errorHandler() { // error: AxiosError<IAxiosErrorResponse & { message: string }, IAxiosErrorResponeData>,
  // if (typeof window === 'undefined' || error.response?.status === 403 || !error.response) return
  //
  // const errorData: IErrorResponse = {
  //   statusCode: error.response.status,
  //   message: error.response.data.message,
  // }
  //
  // const event = new CustomEvent(AppEvents.HTTP_ERROR, { detail: errorData })
  // document.dispatchEvent(event)
  // }

  private checkResponseStatus<T extends {}>(result: IResponseData<T>) {
    if (typeof result !== 'object') return

    if ('statusCode' in result && result.statusCode >= 400 && result.statusCode < 600) {
      throw result
    }
  }

  // private emitResponseMessage() {
  //   const detail: IErrorResponse = {
  //     statusCode: result.status ? result!.status : 200,
  //     message: result.data.message,
  //   }
  //
  //   if (result.status < 400 && typeof window !== 'undefined') {
  //     const event = new CustomEvent(AppEvents.HTTP_SUCCESS, { detail })
  //     document.dispatchEvent(event)
  //   }
  // }
  // }
}
