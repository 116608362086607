import { styled } from '@mui/material'

export const ContentWrapper = styled('div')(
  ({ theme }) => `
  padding-left: ${theme.spacing(3.75)};
  padding-right: ${theme.spacing(2.25)};
  padding-top: ${theme.spacing(3)};
`,
)

export const TitleWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
