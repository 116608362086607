import { createTheme } from '@mui/material'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#3FACA8',
    },
    secondary: {
      main: '#181F32',
    },
    text: {
      primary: '#181F32',
    },
  },
  shape: {
    borderRadius: 20,
  },
  typography: {
    fontFamily: 'Noto Sans',
    button: {
      textTransform: 'none',
    },

    h1: {
      fontSize: '29px',
      fontWeight: 700,
      lineHeight: '1.35',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '1.35',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '1.35',
    },
    h5: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '1.35',
    },
    h6: {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '1.33',
    },
    subtitle1: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: 1.35,
    },
    subtitle2: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: 1.35,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.35,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.33,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {},
      },
    },
  },
})
