import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS, STORAGE_KEYS } from 'src/constants'
import { authServie } from 'src/services'
import { useRefreshToken } from './use-refresh-token.hook'

export const useAuthUser = () => {
  const { mutateAsync: updateAccessToken } = useRefreshToken()

  return useQuery([QUERY_KEYS.GET_AUTH_USER], async () => {
    const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)
    let acessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)

    if (!refreshToken) {
      return null
    }

    if (!acessToken) {
      const resp = await updateAccessToken()
      acessToken = resp?.accessToken
    }

    const resp = await authServie.checkIsAuthenticated(acessToken)

    if (!resp) {
      const tokenData = await updateAccessToken()
      return authServie.checkIsAuthenticated(tokenData?.accessToken)
    }

    return resp
  })
}
