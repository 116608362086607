import { useMutation } from '@tanstack/react-query'

import { STORAGE_KEYS } from 'src/constants'
import { impactService } from 'src/services'

export const useSetImpactLike = () => {
  return useMutation(
    (impactId: string) => {
      const guestId = localStorage.getItem(STORAGE_KEYS.GUEST_ID)

      return impactService.setImpactLike(impactId, guestId)
    },
    {
      onSuccess(data) {
        if (!data) return

        if (!localStorage.getItem(STORAGE_KEYS.GUEST_ID)) {
          localStorage.setItem(STORAGE_KEYS.GUEST_ID, data.guestId)
        }
      },
    },
  )
}
