import axios from 'axios'

import { HttpService } from './http.service'
import { IImpactsResponse } from 'src/types'

class ImpactService extends HttpService {
  constructor() {
    super(axios)
  }

  getProductImpacts(productId: string, locale: string, guestId?: string) {
    return this.get<IImpactsResponse>(`product/${productId}/impacts/${locale}`, {
      params: { guestId },
    })
  }

  setImpactLike(impactId: string, guestId?: string) {
    return this.post<{ guestId: string }, { guestId: string }>(`impacts/${impactId}/likes`, {
      guestId,
    })
  }

  unsetImpactLike(impactId: string, guestId: string) {
    return this.delete(`impacts/${impactId}/likes`, { params: { guestId } })
  }
}

export const impactService = new ImpactService()
