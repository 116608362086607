import { Typography } from '@mui/material'

import { LocaleSelect } from '../locale-select'
import { ContentSt, FooterSt, versionSt } from './page-footer.styled'

export const PageFooter = () => {
  return (
    <FooterSt>
      <ContentSt>
        <LocaleSelect />
        <Typography className={versionSt} variant="body2">
          v{process.env.REACT_APP_VERSION}
        </Typography>
      </ContentSt>
    </FooterSt>
  )
}
