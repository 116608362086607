import { NextPageIcon, RightButton } from './navigation.styled'

type NextPageProps = {
  onClick: () => void
}

export const NextPage = ({ onClick }: NextPageProps) => {
  return (
    <RightButton size="medium" aria-label="Next Page" onClick={onClick}>
      <NextPageIcon />
    </RightButton>
  )
}
