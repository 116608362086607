import { IImpact } from 'src/types'

import { ImpactCard } from './impact-card.component'
import { List } from './impact-cards-ver.styled'
import { useState } from 'react'
import { TitleSt } from 'src/pages/impacts/impacts.styled'
import { useTranslation } from 'react-i18next'

interface IProps {
  items: IImpact[]
}

export const ImpactsCardsVer = ({ items }: IProps) => {
  const [isMobile, ] = useState<boolean>(window.innerWidth < 770)
  const [t, ] = useTranslation()

  return (
    <>    
    <TitleSt isMobile={isMobile} variant="h2">{t('impacts-header-title')}</TitleSt>

    <List>
      {items.map((item) => (
        <ImpactCard
          unSdg={item.un_sdg}
          key={item.id}
          media={item.media}
          mainMedia={item.mainMedia}
          description={item.description}
          title={item.quantity}
          subtitle={item.name}
          image={item.image_medium || item.image}
          likes={item.totalLikes}
          liked={item.liked}
          impactId={item.id}
        />
      ))}
    </List>
    </>

  )
}
