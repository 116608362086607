import { useTheme } from '@mui/material'

interface IArrowIconProps {
  className?: string
}

const ArrowIcon = ({ className }: IArrowIconProps) => {
  const theme = useTheme()

  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 7 11"
      xmlns="http://www.w3.org/2000/svg"
      fill={theme.palette.primary.dark}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11616 5.50005L0.558105 0.941989L1.44199 0.0581055L6.88393 5.50005L1.44199 10.942L0.558105 10.0581L5.11616 5.50005Z"
      />
    </svg>
  )
}

export default ArrowIcon
