import { IMedia } from 'src/types'
import { useTheme } from '@mui/material'

import { MediaRender } from '../media-render'
import { ItemSt, LinkSt, MediaListSt, TitleSt, mediaSt } from './media-list.styled'

interface IProps {
  media: IMedia[]
  size?: number
}

export const MediaList = ({ media, size }: IProps) => {
  const theme = useTheme()

  return (
    <MediaListSt>
      {media.map((item) => {
        return (
          <ItemSt key={item.id}>
            <LinkSt href={item.link} target="_blank" size={size}>
              <MediaRender
                className={mediaSt(theme)}
                type={item.type}
                alt={item.title}
                link={item.link_small || item.link}
              />
            </LinkSt>
            <TitleSt>{item.title}</TitleSt>
          </ItemSt>
        )
      })}
    </MediaListSt>
  )
}
