import { ReactNode, createContext } from 'react'
import { useParams } from 'react-router-dom'

import { useDrawer } from 'src/hooks'

export const AnimNavigationContext = createContext<{
  journeyDetailsDrawer: ReturnType<typeof useDrawer>
}>({} as any)

interface IProps {
  children: ReactNode
  journeyDetails?: boolean
}

export const JourneyNavContextProvider = ({ children, journeyDetails }: IProps) => {
  const { productId, lng } = useParams()

  const journeyDetailsDrawer = useDrawer({
    initOpen: !!journeyDetails,
    closeNavigation: `/${lng}/product/${productId}/journey`,
    openNavigation: `/${lng}/product/${productId}/journey/details`,
  })

  return (
    <AnimNavigationContext.Provider
      value={{
        journeyDetailsDrawer,
      }}
    >
      {children}
    </AnimNavigationContext.Provider>
  )
}
