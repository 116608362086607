import { useTranslation } from 'react-i18next'
import { GpsLogIcon } from 'src/assets/icons'

import {  LogButton } from './journey-log-button.styled'
import { IStep } from 'src/types'
import { TransactionsModal } from '../transactions-modal'
import { useState } from 'react'

interface IProps {
  step: IStep
  isMobile: boolean
}

export const JourneyLogButton = (props: IProps) => {
  const [t] = useTranslation()
  
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)


  return (
    <>        
    
    <div style={{display: 'flex', flexDirection: "column", width: "100%"}}>
      <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
        <LogButton startIcon={<GpsLogIcon />} variant="contained" onClick={handleOpen}>
          {t('view-log-button-text')}
        </LogButton>
        <TransactionsModal
        stepId={props.step.id}
        stepCode={props.step.code}
        stepLocation={props.step.location_address}
        open={open}
        handleClose={handleClose}
      />        
      </div>
      
    </div>
    </>
  )
}
