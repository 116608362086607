import Carousel from 'react-material-ui-carousel'

import { IMedia } from 'src/types'

import { ImageSt, VideoSt } from './image-carousel.styled'

interface IProps {
  images?: Array<IMedia>
}

export const ImageCarousel = ({ images }: IProps) => {
  const media = images.filter(
    (item) => item.type.startsWith('image') || item.type.startsWith('video'),
  )

  return (
    <>
      <Carousel
        animation="slide"
        autoPlay={false}
        navButtonsAlwaysVisible={false}
        navButtonsAlwaysInvisible
      >
        {media.map((item) => {
          if (item.type.startsWith('video')) {
            return <VideoSt key={item.id} controls src={item.link} />
          }

          return <ImageSt key={item.id} alt={item.title} src={item.link} />
        })}
      </Carousel>
    </>
  )
}
