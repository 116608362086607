import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { QUERY_KEYS } from 'src/constants'
import { localeService } from 'src/services'

export const useProductLocales = () => {
  const { productId } = useParams()

  return useQuery([QUERY_KEYS.GET_PRODUCT_LOCALES, productId], () =>
    productId ? localeService.getProductLocales(productId) : null,
  )
}
