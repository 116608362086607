import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { QUERY_KEYS, STORAGE_KEYS, URL_QUERY } from 'src/constants'

export const useAuthentication = () => {
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()

  const accessToken = searchParams.get(URL_QUERY.ACCESS_TOKEN)
  const refreshToken = searchParams.get(URL_QUERY.REFRESH_TOKEN)
  useEffect(() => {
    if (!accessToken || !refreshToken) return

    localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, accessToken)
    localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken)
    ;(async () => {
      queryClient.removeQueries([QUERY_KEYS.GET_AUTH_USER])
      queryClient.removeQueries([QUERY_KEYS.GET_ME])
    })()

    setSearchParams((prev) => {
      prev.delete(URL_QUERY.ACCESS_TOKEN)
      prev.delete(URL_QUERY.REFRESH_TOKEN)

      return prev
    })
  }, [accessToken, refreshToken])
}
