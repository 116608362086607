import { useEffect } from 'react'
import { useAccountStyles } from '../fetch'

export const useInjectCss = () => {
  const { data: cssLink } = useAccountStyles()

  useEffect(() => {
    // eslint-disable-next-line
    ; (async function() {
      if (!cssLink) return

      const link = document.createElement('link')
      link.rel = 'stylesheet'

      const response = await fetch(cssLink)
      const css = await response.text()

      link.href = `data:text/css;base64,${btoa(css)}`
      document.head.appendChild(link)
    })()
  }, [cssLink])
}
