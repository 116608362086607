import { IconButton, styled } from '@mui/material'

export const LinksList = styled('ul')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
`

export const LinkButtonSt = styled(IconButton as any)`
  width: 35px;
  height: 35px;

  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 50%;
`
