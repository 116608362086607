import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PageWrapper, PreviousPage, JourneySteps, PageContainer } from 'src/components'
import { useAnimNavContext, useIsTablet, useProductJourney } from 'src/hooks'
import { Title } from './title.component'
import { MutableRefObject } from 'react'

interface IProps {
  topRef?: MutableRefObject<HTMLSpanElement>
}

export default function JourneyDetailed(props:IProps) {
  const {topRef} = props
  const [, i18n] = useTranslation()
  const { productId } = useParams()
  const { journeyDetailsDrawer } = useAnimNavContext()
  const isTablet = useIsTablet()
  const { data } = useProductJourney(productId, i18n.language)

  const journey = data?.product?.journey
  const steps = journey?.steps || []

  const handleNavigate = () => journeyDetailsDrawer.handleClose()

  return (
    <PageWrapper>
      <PageContainer>
        {isTablet && <PreviousPage onClick={handleNavigate} />}
        <Title journeyName={journey.journey_name} />
        <JourneySteps detailed steps={steps} topRef={topRef}/>
      </PageContainer>
    </PageWrapper>
  )
}
