import { AccountLinks } from '../account-links'
import {
  AvatarSt,
  AvatarWrapper,
  ContainerSt,
  LinksWrapper,
  LocationSt,
  TitleSt,
} from './account-info.styled'

export const AccountInfo = ({ accountName, image, location, socials, website, email }) => {
  return (
    <ContainerSt>
      <AvatarWrapper>
        <AvatarSt src={image} alt={accountName} />
        <div>
          <TitleSt variant="h2">{accountName}</TitleSt>
          <LocationSt variant="body2">{location}</LocationSt>
        </div>
      </AvatarWrapper>
      <LinksWrapper>
        <AccountLinks socials={socials} website={website} email={email} />
      </LinksWrapper>
    </ContainerSt>
  )
}
