import { ReactNode } from 'react'
import { ListItem } from '@mui/material'

import {
  CollapsedContent,
  ListIconSt,
  CollapsedTitle,
  ContentSubtitle,
} from './transaction-details.styled'

interface IProps {
  icon: ReactNode
  title: string
  subtitle: ReactNode
}

export const CollapsedItem = ({ icon, title, subtitle }: IProps) => {
  return (
    <ListItem disableGutters alignItems="flex-start">
      <ListIconSt>{icon}</ListIconSt>
      <CollapsedContent>
        <CollapsedTitle>{title}</CollapsedTitle>
        <ContentSubtitle>{subtitle}</ContentSubtitle>
      </CollapsedContent>
    </ListItem>
  )
}
