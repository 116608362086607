import { GoogleMap } from '@react-google-maps/api'

import { IMarker, IStep } from 'src/types'
import { fitMapWithinMarkers } from 'src/utils'

import { mapViewSt } from './map-view.styled'
import { CustomMarker } from './custom-marker'
import { MutableRefObject, useRef } from 'react'
import { useMap } from 'src/contexts/journey-map.context'

interface IProps {
  markers: IMarker[]
  satelliteView?: boolean
  steps?: IStep[]
  scrollToRef?: MutableRefObject<HTMLSpanElement>
}

export const MapView = ({ markers, satelliteView, steps, scrollToRef }: IProps) => {

  const { setMapRef, setMapCenter } = useMap();
  const mapRef = useRef<google.maps.Map | null>(null);

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    setMapRef(map);
    if (markers.length === 1) {
      setMapCenter(markers[0].latitude, markers[0].longitude, 6);
      return;
    }
    fitMapWithinMarkers(map, markers);
  };

  const findObjectsWithEqualCoordinates = () => {
    const coordinateMap = {};
  
    steps && steps.forEach((obj, index) => {
      const key = `${obj.latitude}_${obj.longitude}`;
      if (!coordinateMap[key]) {
        coordinateMap[key] = [];
      }
      coordinateMap[key].push({ index, text: obj.name });
    });
  
    const combinedTextArray = steps && steps.map((obj) => {
      const key = `${obj.latitude}_${obj.longitude}`;
      if (coordinateMap[key].length > 1) {
        return coordinateMap[key].map((entry:any) => entry.text);
      }
      return (typeof obj.name === 'string') ? [obj.name] : obj.name;
    });
  
    return combinedTextArray;
  };
  const checkedSteps = findObjectsWithEqualCoordinates()
  return (
    <GoogleMap 
      onLoad={handleLoad}
      mapContainerClassName={mapViewSt}
      zoom={6}
      options={{
        clickableIcons: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: true,
        disableDefaultUI: true,
        mapTypeId: satelliteView ? 'satellite' : 'roadmap',
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
      }}        
    >
      {markers.map((marker, index) => (
        <CustomMarker 
          marker={marker} 
          satelliteView={satelliteView} 
          stepLocation={steps[index].location_name} 
          stepName={checkedSteps[index]} 
          scrollToRef={scrollToRef}
        />
      ))}
    </GoogleMap>
  )
}
