interface IProps {
  className?: string
}

export const LocationIcon = ({ className }: IProps) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="24 / maps / location">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.953125 8.125H2.23746C2.52567 10.7323 4.59586 12.8025 7.20312 13.0907V14.375H8.45312V13.0907C11.0604 12.8025 13.1306 10.7323 13.4188 8.125H14.7031V6.875H13.4188C13.1306 4.26774 11.0604 2.19755 8.45312 1.90933V0.625H7.20312V1.90933C4.59586 2.19755 2.52567 4.26774 2.23746 6.875H0.953125V8.125ZM3.45319 7.49999C3.45319 5.08374 5.41194 3.12499 7.82819 3.12499C10.2444 3.12499 12.2032 5.08374 12.2032 7.49999C12.2032 9.91623 10.2444 11.875 7.82819 11.875C5.41194 11.875 3.45319 9.91623 3.45319 7.49999ZM7.82817 4.99999C9.20888 4.99999 10.3282 6.11928 10.3282 7.49999C10.3282 8.8807 9.20888 9.99999 7.82817 9.99999C6.44746 9.99999 5.32817 8.8807 5.32817 7.49999C5.32817 6.11928 6.44746 4.99999 7.82817 4.99999ZM6.57807 7.5C6.57807 6.80964 7.13772 6.25 7.82807 6.25C8.51843 6.25 9.07807 6.80964 9.07807 7.5C9.07807 8.19036 8.51843 8.75 7.82807 8.75C7.13772 8.75 6.57807 8.19036 6.57807 7.5Z"
        fill="#181F32"
      />
    </g>
  </svg>
)
