import { useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS, STORAGE_KEYS } from 'src/constants'

export const useLogout = () => {
  const queryClient = useQueryClient()

  return async () => {
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN)
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)

    queryClient.removeQueries([QUERY_KEYS.GET_AUTH_USER])
    queryClient.removeQueries([QUERY_KEYS.GET_ME])
  }
}
