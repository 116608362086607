import { ReactNode } from 'react'
import { MenuItem, ListItemIcon, ListItemText, Link } from '@mui/material'

interface IProps {
  icon: ReactNode
  text?: string
  divider?: boolean
  link: string
}

export const PopUpLink = ({ icon, text, divider, link }: IProps) => {
  return (
    <MenuItem sx={{ padding: 2 }} divider={divider}>
      <ListItemIcon>{icon}</ListItemIcon>
      {text && (
        <Link href={link} color="currentcolor" underline="none">
          <ListItemText>{text}</ListItemText>
        </Link>
      )}
    </MenuItem>
  )
}
