import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend'

import languageDetector from './detector.i18n'
import { backendOptions } from './be-options.i18n'

i18n
  .use(HttpApi)
  .use(languageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    backend: backendOptions,
    fallbackLng: 'en',
    load: 'languageOnly',
    ns: ['translations'],
    defaultNS: 'translations',
    detection: {
      order: ['urlDetector', 'localStorage', 'navigator'],
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  })

export default i18n
