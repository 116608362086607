import { Divider, useMediaQuery, useTheme } from '@mui/material'
import { IMedia, IUnSdg } from 'src/types'

import { ImageCarousel } from '../image-carousel'
import { UnSdgList } from './un-sdg-list.component'
import { LikesCounter } from './likes-counter.component'
import {
  UpperWrapper,
  Item,
  ItemImage,
  ItemText,
  ItemTitle,
  BottomWrapper,
  CarouselWrapper,
  ShareWrapper,
} from './impact-cards-ver.styled'
import { MediaList } from '../media-list'
import { TwitterShare } from '../share-button'

interface IProps {
  title: string | number
  subtitle: string
  image: string
  description: string
  media: IMedia[]
  mainMedia: IMedia[]
  unSdg: IUnSdg[]
  likes: number
  impactId: string
  liked: boolean
}

export const ImpactCard = (props: IProps) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Item id={props.impactId}>
      <UpperWrapper>
        <ItemImage
          variant="square"
          alt={props.subtitle}
          src={props.image}
          style={{ borderRadius: 6 }}
        />
        <div>
          <ItemTitle variant="h2">{props.title}</ItemTitle>
          <ItemText variant="subtitle2">{props.subtitle}</ItemText>
        </div>
        {!isTablet && <UnSdgList unSdg={props.unSdg} />}
      </UpperWrapper>
      {props.mainMedia.length ? (
        <CarouselWrapper>
          <ImageCarousel images={props.mainMedia} />
        </CarouselWrapper>
      ) : null}
      <Divider />
      <BottomWrapper>
        <LikesCounter count={props.likes} userLiked={props.liked} impactId={props.impactId} />
        {props.media.length ? <MediaList media={props.media} /> : null}
        {isTablet && <UnSdgList unSdg={props.unSdg} />}
        <ItemText variant="body1">{props.description}</ItemText>
        <ShareWrapper>
          <TwitterShare title={props.subtitle} />
        </ShareWrapper>
      </BottomWrapper>
    </Item>
  )
}
