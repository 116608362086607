import { Avatar, Typography, styled } from '@mui/material'
import { HeartFilledIcon, HeartIcon } from 'src/assets/icons'

export const List = styled('ul')(
  ({ theme }) => `
  padding: ${theme.spacing(1.25)};
  position: relative;
  top: 40px;


  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(5.5)};
`,
)

export const Item = styled('li')`
  max-width: 100%;
  height: 100%;

  border-radius: 15px;
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
`

export const UpperWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(2.5)};

  display: flex;
  gap: ${({ theme }) => theme.spacing(2.5)};
`

export const CarouselWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(1.25)};
`

export const BottomWrapper = styled('div')(
  ({ theme }) => ` 
  padding: ${theme.spacing(1.25)} ${theme.spacing(2.5)} ${theme.spacing(2.5)};

  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1.25)};
`,
)

export const ItemImage = styled(Avatar)(
  () => `
  width: 80px;
  height: 80px;
`,
)
export const ItemText = styled(Typography)``

export const ItemTitle = styled(Typography)`
  font-size: 36px;
`

export const LikesCounterSt = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;
`

export const LikesCounterIcon = styled(HeartIcon as any)`
  width: 15px;
  height: 15px;
  fill: ${({ theme }) => theme.palette.secondary.main};
`

export const LikesCounterIconFilled = styled(HeartFilledIcon as any)`
  width: 15px;
  height: 15px;
  fill: #ff005c;
`

export const LikesCounterText = styled(Typography)`
  font-size: 10px;
  font-weight: 600;
  line-height: 1.4;
`

export const ShareWrapper = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.25)};
  justify-content: flex-end;
`
