import { HTMLAttributes, ReactNode } from 'react'

import { PageWrapperSt } from './page-wrapper.styled'

interface IProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode,
  isJourney?: boolean
}

export const PageWrapper = ({ children, ...props }: IProps) => {
  return <PageWrapperSt {...props}>{children}</PageWrapperSt>
}
