import { Avatar, ListItem } from '@mui/material'

interface IProps {
  image: string
  title: string
}

export const PopUpUser = ({ title, image }: IProps) => {
  return (
    <ListItem style={{ gap: 10 }}>
      <Avatar alt={title} src={image} />
      {title}
    </ListItem>
  )
}
