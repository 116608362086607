import { getPolygonscanTxUrl } from 'src/utils'
import { useTranslation } from 'react-i18next'

import { ButtonIcon, ButtonSt } from './bc-ts-button.styled'

type BlockchainTransactionButtonProps = {
  hash: string
}

export const BcTxButton = ({ hash }: BlockchainTransactionButtonProps) => {
  const [t] = useTranslation()

  return (
    <ButtonSt
      startIcon={<ButtonIcon />}
      size="small"
      variant="contained"
      color="primary"
      href={getPolygonscanTxUrl(hash)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('bc-explorer-button-text')}
    </ButtonSt>
  )
}
