import { LeftButton, PrevPageIcon } from './navigation.styled'

type PreviousPageProps = {
  onClick: () => void
  absolute?: boolean
}

export const PreviousPage = ({ onClick, absolute }: PreviousPageProps) => {
  const handleClick = () => onClick()

  return (
    <LeftButton size="medium" aria-label="Previous Page" onClick={handleClick} absolute={absolute}>
      <PrevPageIcon />
    </LeftButton>
  )
}
