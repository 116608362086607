import { useNavigate } from 'react-router-dom'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, NativeSelect } from '@mui/material'
import moment from 'moment'

import { useProductLocales } from 'src/hooks'

export const LocaleSelect = () => {
  const { i18n } = useTranslation()
  const { data: locales } = useProductLocales()
  const navigate = useNavigate()

  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    const code = event.target.value
    if (i18n.language === code) return

    const newUrl = window.location.pathname.replace(`/${i18n.language}`, `/${code}`)
    navigate(newUrl, { replace: true })
    i18n.changeLanguage(code)
    moment.locale(code)
  }

  if (!locales?.length || locales?.length === 1) {
    return null
  }

  return (
    <FormControl>
      <NativeSelect defaultValue={i18n.language} value={i18n.language} onChange={handleChange}>
        {locales?.map((locale) => (
          <option key={locale.id} value={locale.code}>
            {locale.name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  )
}
