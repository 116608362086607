import { styled } from '@mui/material'

export const ImageSt = styled('img')`
  width: 100%;
  height: 178px;
  object-fit: cover;
`

export const VideoSt = styled('video')`
  width: 100%;
  height: 178px;
  object-fit: cover;
  display: block;
`
