import { ReactNode } from 'react'

import {
  CardSt,
  ContentWrapperClosed,
  ContentWrapperOpen,
  Line,
  SwipeableDrawerSt,
} from './bottom-card.styled'

interface IProps {
  closedNode: ReactNode
  children: ReactNode
  open: boolean
  handleOpen: () => void
  handleClose: () => void
  enabled: boolean
}

export const BottomCard = (props: IProps) => {

  return (
    <SwipeableDrawerSt
      style={{display: props.enabled ? null : 'none'}}
      anchor="bottom"
      open={props.open}
      onClose={props.handleClose}
      onOpen={props.handleOpen}
      swipeAreaWidth={320}
      disableSwipeToOpen={false}
      allowSwipeInChildren={true}
      disableBackdropTransition
      ModalProps={{
        keepMounted: true,
      }}
    >
      <CardSt isOpen={props.open}>
        <Line/>
        <ContentWrapperClosed>{props.closedNode}</ContentWrapperClosed>
      </CardSt>
      <ContentWrapperOpen isOpen={props.open}>
        <Line />
        {props.children}
      </ContentWrapperOpen>
    </SwipeableDrawerSt>
  )
}
