import { Typography } from '@mui/material'
import { TransactionsList } from '../transactions-list'
import { ModalPaper } from './transactions-modal.styled'
import { useStepTransactions } from 'src/hooks'

interface IProps {
  stepLocation: string
  stepCode: string
  stepId: string
}

export const ModalInner = (props: IProps) => {
  const { data: transactions } = useStepTransactions(props.stepId)

  return (
    <ModalPaper>
      <Typography variant="h2">{props.stepCode}</Typography>
      <TransactionsList stepLocation={props.stepLocation} transactions={transactions} />
    </ModalPaper>
  )
}
