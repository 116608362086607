interface IProps {
  className?: string
}

export const ImageIcon = ({ className }: IProps) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="24 / basic / image">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.54883 1.25H12.5488C13.2392 1.25 13.7988 1.80964 13.7988 2.5V12.5C13.7988 13.1904 13.2392 13.75 12.5488 13.75H2.54883C1.85847 13.75 1.29883 13.1904 1.29883 12.5V2.5C1.29883 1.80964 1.85847 1.25 2.54883 1.25ZM2.54887 2.50001V9.74104L5.0488 7.24111L7.23632 9.42863L11.2988 5.36611L12.5489 6.61614V2.50001H2.54887ZM2.54887 12.5V11.5088L5.0488 9.00888L8.53993 12.5H2.54887ZM12.5489 12.5H10.3077L8.1202 10.3125L11.2988 7.13388L12.5489 8.38391V12.5ZM8.79883 5C8.79883 3.96447 7.95936 3.125 6.92383 3.125C5.88829 3.125 5.04883 3.96447 5.04883 5C5.04883 6.03553 5.88829 6.875 6.92383 6.875C7.95936 6.875 8.79883 6.03553 8.79883 5ZM6.29887 5.00001C6.29887 4.65483 6.57869 4.37501 6.92387 4.37501C7.26905 4.37501 7.54887 4.65483 7.54887 5.00001C7.54887 5.34519 7.26905 5.62501 6.92387 5.62501C6.57869 5.62501 6.29887 5.34519 6.29887 5.00001Z"
        fill="#181F32"
      />
    </g>
  </svg>
)
