interface IProps {
  className?: string
}

export const HashIcon = ({ className }: IProps) => (
  <svg
    width="15"
    height="15"
    className={className}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="24 / symbols / hash">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75484 1.25H5.58635L5.2358 5H2.78185L2.665 6.25H5.11895L4.88525 8.75H2.19766L2.08081 10H4.7684L4.41786 13.75H5.58635L5.9369 10H8.27385L7.9233 13.75H9.09179L9.44234 10H11.8961L12.013 8.75H9.55919L9.79289 6.25H12.4803L12.5972 5H9.90973L10.2603 1.25H9.09179L8.74124 5H6.40429L6.75484 1.25ZM6.28744 6.25L6.05375 8.75H8.3907L8.62439 6.25H6.28744Z"
        fill="#181F32"
      />
    </g>
  </svg>
)
