import { BottomCard } from 'src/components'
import { useAccount, useDrawer, useIsTablet, useProductImpacts } from 'src/hooks'
import { IProduct } from 'src/types'

import { Content } from './content.component'
import { useTranslation } from 'react-i18next'
import { EType, useDrawerer } from 'src/contexts/modal-drawer.context'

interface IProps {
  product: IProduct
}

export const ProductCard = ({ product }: IProps) => {
  const [, i18n] = useTranslation()
  const productDrawer = useDrawer({})
  const { data: account } = useAccount(product.account_id, i18n.language)
  const { data } = useProductImpacts(product.id, i18n.language)
  const isTablet = useIsTablet()

  const impacts = data?.impacts || []

  if (!account) return null

  if (!isTablet) {
    return (
      <Content
        media={product.media}
        productDescription={product.description}
        productName={product.name}
        accountImage={account.image_small || account.image}
        accountHolder={account.account_holder}
        impacts={impacts}
      />
    )
  }

  
  const drawerer = useDrawerer()

  return (
    <div>
      <BottomCard
        enabled={drawerer.active === EType.PRODUCT}
        handleClose={productDrawer.handleClose}
        open={productDrawer.open}
        handleOpen={productDrawer.handleOpen}
        closedNode={
          <Content
            media={product.media}
            productDescription={product.description}
            productName={product.name}
            accountImage={account.image_small || account.image}
            accountHolder={account.account_holder}
            impacts={impacts}
          />
        }
      >
        <Content
          productDescription={product.description}
          media={product.media}
          productName={product.name}
          accountImage={account.image_small || account.image}
          accountHolder={account.account_holder}
          impacts={impacts}
        />
      </BottomCard>
    </div>
  )
}
