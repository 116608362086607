import axios from 'axios'

import { HttpService } from './http.service'
import { ITransaction } from 'src/types'

class TransactionService extends HttpService {
  constructor() {
    super(axios)
  }

  getStepTransactions(stepId: string) {
    return this.get<ITransaction[]>(`step/${stepId}/scans`)
  }
}

export const transactionService = new TransactionService()
