import { useSwiper } from 'swiper/react'
import { useParams } from 'react-router-dom'

import { IUnSdg } from 'src/types'
import { Item, ItemBottom, ItemImage, ItemText, LinkSt } from './impact-cards-hor.styled'
import { useDrawerer } from 'src/contexts/modal-drawer.context'

interface IProps {
  title: string | number
  subtitle: string
  image: string
  unSdg: Array<IUnSdg>
  id: string
}

export const ImpactCard = ({ image, title, subtitle, id }: IProps) => {
  const swiper = useSwiper()
  const { productId, lng } = useParams()
  const drawerer = useDrawerer()

  const handleLink = () => {
    drawerer.setActive(null)
    swiper.slideTo(2)
  }

  return (
    <Item>
      <LinkSt to={`/${lng}/product/${productId}/impacts#${id}`} onClick={handleLink}>
        <ItemImage variant="square" alt={subtitle} src={image} />
        <ItemBottom>
          <div>
            <ItemText variant="h4">{title}</ItemText>
            <ItemText variant="body2">{subtitle}</ItemText>
          </div>
        </ItemBottom>
      </LinkSt>
    </Item>
  )
}
