import { HttpBackendOptions } from 'i18next-http-backend'

import { loadResources } from './load.i18n'

export const backendOptions: HttpBackendOptions = {
  loadPath: '{{lng}}',
  request: async (options, url, payload, callback) => {
    try {
      const data = await loadResources(url)

      callback(null, {
        data,
        status: 200,
      })
    } catch (e) {
      callback(e, {
        data: {},
        status: 500,
      })
    }
  },
}
