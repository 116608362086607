import { ThemeProvider as Provider } from '@mui/material'
import { ReactNode } from 'react'

import { theme } from './theme.provider'

interface IProps {
  children: ReactNode
}

export const ThemeProvider = ({ children }: IProps) => {
  return <Provider theme={theme}>{children}</Provider>
}
