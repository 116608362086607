import { ReactNode, createContext, useContext, useRef } from "react";

interface IMapContextProps {
    setMapRef: (map: google.maps.Map) => void;
    setMapCenter: (latitude: number, longitude: number, zoom: number) => void;
  }
  
  const MapContext = createContext<IMapContextProps | undefined>(undefined);
  
  export const MapProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const mapRef = useRef<google.maps.Map | null>(null);
    const setMapRef = (map: google.maps.Map) => {
      mapRef.current = map;
    };
    const setMapCenter = (latitude: number, longitude: number, zoom: number) => {
      if (mapRef.current) {
        const map = mapRef.current;
        map.panTo({ lat: latitude, lng: longitude });
        map.setZoom(zoom);
      }
    };
    const contextValue: IMapContextProps = {
      setMapRef,
      setMapCenter,
    };
  
    return <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>;
  };
  
  export const useMap = () => {
    const context = useContext(MapContext);
    if (!context) {
      throw new Error('useMap must be used within a MapProvider');
    }
    return context;
  };