import { Link, useParams } from 'react-router-dom'

import { IProduct } from 'src/types'

import { DetailsTitle, ProductsListSt, ProductImage } from './account-details.styled'
import { useTranslation } from 'react-i18next'

interface IProps {
  products: IProduct[]
}
export const ProductsList = ({ products }: IProps) => {
  const { lng } = useParams()
  const [t] = useTranslation()

  return (
    <div>
      <DetailsTitle variant="subtitle1">{t('product-list-title')}</DetailsTitle>
      <ProductsListSt>
        {products.map((product) => (
          <li key={product.id}>
            <Link to={`/${lng}/product/${product.id}`}>
              <ProductImage alt={product.name} src={product.image} />
            </Link>
          </li>
        ))}
      </ProductsListSt>
    </div>
  )
}
