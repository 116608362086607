export const QUERY_KEYS = {
  GET_ACCOUNT: 'GET_ACCOUNT',
  GET_ACCOUNT_PRODUCTS: 'GET_ACCOUNT_PRODUCTS',
  GET_PRODUCT: 'GET_PRODUCT',
  GET_PRODUCT_IMPACTS: 'GET_PRODUCT_IMPACTS',
  GET_STEP_TRANSACTIONS: 'GET_STEP_TRANSACTIONS',
  GET_PRODUCT_JOURNEY: 'GET_PRODUCT_JOURNEY',
  GET_ACCOUNTS: 'GET_ACCOUNTS',
  GET_PRODUCT_LOCALES: 'GET_PRODUCT_LOCALES',

  GET_AUTH_USER: 'GET_AUTH_USER',
  GET_ME: 'GET_ME',
  GET_ACCOUNT_STYLES: 'GET_ACCOUNT_STYLES',
}
