import { useParams } from 'react-router-dom'

import { History } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'

import { JourneyNavContextProvider } from 'src/contexts'

import JourneyPage from '../journey'
import ProductPage from '../product'
import ImpactsPage from '../impacts'
import { SwiperSlideSt, SwiperSt } from './page-swiper.styled'
import { DrawerProvider } from 'src/contexts/modal-drawer.context'

interface IProps {
  journeyDetails?: boolean
}

export const SwiperPage = ({ journeyDetails }: IProps) => {
  const { productId, lng } = useParams()

  return (
    <>        
    <DrawerProvider>
      <SwiperSt
        history={{
          key: undefined,
          root: `/${lng}/product/${productId}`,
        }}
        allowTouchMove={false}
        modules={[History]}
      >

          <SwiperSlideSt data-history={`/product`}>
            <ProductPage />
          </SwiperSlideSt>
          <SwiperSlideSt data-history={`/journey`}>
            <JourneyNavContextProvider journeyDetails={journeyDetails}>
              <JourneyPage />
            </JourneyNavContextProvider>
          </SwiperSlideSt>
          <SwiperSlideSt data-history={`/impacts`} constantOverflow>
            <ImpactsPage />
          </SwiperSlideSt>            
      </SwiperSt>        
      </DrawerProvider>
    </>
  )
}
