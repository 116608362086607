import { ReactNode } from 'react'
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'

interface IProps {
  icon: ReactNode
  text?: string
  onClick: () => void
  divider?: boolean
}

export const PopUpItem = ({ icon, text, onClick, divider }: IProps) => {
  return (
    <MenuItem sx={{ padding: 2 }} aria-label="Home" onClick={onClick} divider={divider}>
      <ListItemIcon>{icon}</ListItemIcon>
      {text && <ListItemText>{text}</ListItemText>}
    </MenuItem>
  )
}
