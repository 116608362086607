export const TimeIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="24 / time / time">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 14.375C3.70304 14.375 0.625 11.297 0.625 7.5C0.625 3.70304 3.70304 0.625 7.5 0.625C11.297 0.625 14.375 3.70304 14.375 7.5C14.375 11.297 11.297 14.375 7.5 14.375ZM7.50004 13.125C10.6066 13.125 13.125 10.6066 13.125 7.49999C13.125 4.39339 10.6066 1.87499 7.50004 1.87499C4.39343 1.87499 1.87504 4.39339 1.87504 7.49999C1.87504 10.6066 4.39343 13.125 7.50004 13.125ZM10.625 6.87499H8.12503V3.74999H6.87503V8.12499H10.625V6.87499Z"
        fill="#181F32"
      />
    </g>
  </svg>
)
