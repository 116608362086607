import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import {
  AccountDetails,
  PreviousPage,
  AccountInfo,
  PageWrapper,
  Loader,
  PageContainer,
} from 'src/components'
import { useAccount, useAccountProducts, useProduct } from 'src/hooks'

import { InfoWrapper, PageWrapperInner, TitleSt } from './account.styled'

export default function Account() {
  const { productId, lng } = useParams()
  const [t, i18n] = useTranslation()
  const navigate = useNavigate()
  const [enabled, setEnabled] = useState(false)
  const { data: product } = useProduct(productId, i18n.language)

  const { data: account } = useAccount(product?.account_id, i18n.language, enabled)
  const { data: products } = useAccountProducts(product?.account_id, enabled)

  useEffect(() => {
    if (product) {
      setEnabled(true)
    }
  }, [product])

  const notCurrentProducts = products?.products.filter((p) => p.id !== productId)

  const handlePrev = () => navigate(`/${lng}/product/${productId}`)

  if (!account || !products) {
    return <Loader />
  }

  return (
    <PageWrapper>
      <PageContainer>
        <PageWrapperInner>
          <PreviousPage onClick={handlePrev} />
          <TitleSt variant="h1">{t('account-header-title')}</TitleSt>
          <InfoWrapper>
            <AccountInfo
              socials={account.socials || {}}
              email={account.email}
              image={account.image_medium || account.image}
              website={account.website}
              location={account.address}
              accountName={account.account_holder}
            />
          </InfoWrapper>
          <AccountDetails
            media={account.media}
            products={notCurrentProducts}
            description={account.description}
          />
        </PageWrapperInner>
      </PageContainer>
    </PageWrapper>
  )
}
