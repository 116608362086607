import { Button, styled } from '@mui/material'

import { EyeIcon } from 'src/assets/icons'

export const ButtonIcon = styled(EyeIcon as any)`
  fill: ${({ theme }) => theme.palette.secondary.main};
`

export const ButtonSt = styled(Button as any)`
  && {
    height: 30px;

    border-radius: 15px;
    background-color: ${({ theme }) => theme.palette.background.default};
    box-shadow: 0px 3px 10px 0px ${({ theme }) => theme.palette.grey.A200};

    color: ${({ theme }) => theme.palette.secondary.main};
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
  }
`
