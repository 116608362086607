import { Route, Routes } from 'react-router-dom'

import Home from './pages/home'
import Account from './pages/account'
import AuthPage from './pages/auth'
import SplashPage from './pages/splash'
import { SwiperPage } from './pages/page-swiper'

import { LngWrapper, RoutesWrapper } from './components'
import { ROUTES } from './constants'
import 'moment/min/locales'

export function App() {
  return (
    <Routes>
      <Route element={<RoutesWrapper />}>
        <Route path="*" element={<SplashPage />} />
        <Route path={ROUTES.AUTH} element={<AuthPage />} />
        <Route path={ROUTES.LANGUAGE} element={<LngWrapper />}>
          <Route path={ROUTES.PRODUCTS} element={<Home />}>
            <Route index element={<SwiperPage />} />
            <Route path={ROUTES.PRODUCT} element={<SwiperPage />} />
            <Route path={ROUTES.ACCOUNT} element={<Account />} />
            <Route path={ROUTES.JOURNEY} element={<SwiperPage />} />
            <Route path={ROUTES.JOURNEY_DETAILED} element={<SwiperPage journeyDetails />} />
            <Route path={ROUTES.IMPACTS} element={<SwiperPage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}
