import { MutableRefObject, useMemo } from 'react'
import { useSwiper } from 'swiper/react'

import { IMarker, IStep } from 'src/types'

import { PreviousPage, NextPage } from '../navigation'
import { MapView } from '../map-view'
import { ContainerSt, MapWrapper } from './journey-map.styled'
import { EType, useDrawerer } from 'src/contexts/modal-drawer.context'

interface IProps {
  steps: IStep[]
  satelliteView: boolean
  scrollToRef?: MutableRefObject<HTMLSpanElement>
}

export const JourneyMap = (props: IProps) => {
  const swiper = useSwiper()
  const markers = useMemo(
    () =>
      props.steps.map<IMarker>((item) => ({
        latitude: item.latitude / 10_000,
        longitude: item.longitude / 10_000,
      })),
    [props.steps],
  )
  const drawerer = useDrawerer()

  const handlePrev = () => {
    drawerer.setActive(EType.PRODUCT)
    swiper.slidePrev()
  }

  const handleNext = () => {
    drawerer.setActive(null)
    swiper.slideNext()
  }

  return (
    <ContainerSt>
      <MapWrapper>
        <MapView satelliteView={props.satelliteView} markers={markers} steps={props.steps} scrollToRef={props.scrollToRef}/>
      </MapWrapper>
      <PreviousPage onClick={handlePrev} absolute />
      <NextPage onClick={handleNext} />
    </ContainerSt>
  )
}
