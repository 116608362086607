import { Avatar, IconButton } from '@mui/material'

import { IImpact } from 'src/types'

import { StepImpactsList } from './journey-step-card.styled'
import { MoreHoriz } from '@mui/icons-material'
import { Dispatch, SetStateAction } from 'react'

interface IProps {
  impacts: IImpact[]
  setShowDescr: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
}

export const ImpactsList = ({ impacts, setShowDescr, isMobile }: IProps) => {
  if(isMobile){
    impacts = impacts.slice(0, 3) 
  }

  return (
    <StepImpactsList>
      {impacts.map((item) => (
        <li key={item.id}>
          <Avatar
            src={item.image_small || item.image}
            alt={item.name}
            variant="square"
            sx={{ borderRadius: '6px' }}
          />
        </li>
      ))}
      {isMobile && <li>
        <IconButton onClick={() => {setShowDescr((prev) => !prev)}}>
          <MoreHoriz />
        </IconButton>
      </li>}
    </StepImpactsList>
  )
}
