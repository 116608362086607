import { ReactNode } from 'react'
import { Link } from '@mui/material'

import { LinkButtonSt } from './account-links.styled'

interface IProps {
  children: ReactNode
  href: string
}

export const LinkButton = ({ href, children }: IProps) => (
  <LinkButtonSt LinkComponent={Link} href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </LinkButtonSt>
)
