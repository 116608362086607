import { useParams } from 'react-router-dom'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { useTranslation } from 'react-i18next'

import { useAuthUser, useMe } from 'src/hooks'
import { MAIN_APP_URL } from 'src/constants'

import { FabSt } from './scan-fab.styled'
import { EUserRoles } from 'src/types'

export const ScanFAB = () => {
  const [t] = useTranslation()
  const { data: authUser } = useAuthUser()
  const { data: me } = useMe()
  const { productId } = useParams()

  const handleNavigate = () => {
    if (!authUser || !productId) {
      return
    }

    window.open(`${MAIN_APP_URL}scans/create?productId=${productId}`, '_blank')
  }

  const isSuperadmin = authUser?.roles?.includes(EUserRoles.superadmin)
  if (!authUser || !me || (!isSuperadmin && !me.accountRoles)) {
    return null
  }

  return (
    <FabSt variant="extended" size="large" onClick={handleNavigate} color="primary">
      <CameraAltIcon />
      {t('scan-button-text')}
    </FabSt>
  )
}
