import { styled } from '@mui/material'

export const ContainerSt = styled('div')`
  justify-content: center;

  text-align: center;

  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    position: sticky;
    z-index: 0;
    left: 0;
    top: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
  }
`

export const MapWrapper = styled('div')`
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 58lvh;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 58lvh;
  }
`
