import axios from 'axios'

import { IAccountProductsResp, IProduct } from 'src/types'
import { HttpService } from './http.service'

class ProductService extends HttpService {
  constructor() {
    super(axios)
  }

  getProduct(productId: string, locale: string) {
    return this.get<IProduct>(`product/${productId}?log=true`, {
      params: {
        locale,
      },
    })
  }

  getAccountProducts(accountId: string) {
    return this.get<IAccountProductsResp>(`account/${accountId}/products`)
  }
}

export const productService = new ProductService()
