interface IProps {
  className?: string
}

export const PinAddIcon = ({ className }: IProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#181F32"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6577 22.7533L12 23.3276L11.3423 22.7533C5.81131 17.9237 3 13.7068 3 10C3 4.75066 7.09705 1 12 1C16.9029 1 21 4.75066 21 10C21 13.7068 18.1887 17.9237 12.6577 22.7533ZM5 10C5 12.8492 7.30661 16.4335 12 20.6634C16.6934 16.4335 19 12.8492 19 10C19 5.8966 15.8359 3 12 3C8.16411 3 5 5.8966 5 10ZM11 6V9H8V11H11V14H13V11H16V9H13V6H11Z"
      fill="inherit"
    />
  </svg>
)
