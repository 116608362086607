import { css } from '@emotion/css'
import { Link, Theme, styled } from '@mui/material'

export const MediaListSt = styled('ul')`
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const ItemSt = styled('li')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.375)};
`

export const LinkSt = styled(Link)<{ size?: number }>`
  display: block;
  width: ${({ size }) => (size ? `${size}px` : '45px')};
  height: ${({ size }) => (size ? `${size}px` : '45px')};
`

export const mediaSt = (theme: Theme) => css`
  && {
    width: inherit;
    height: inherit;
    fill: ${theme.palette.text.primary};

    object-fit: cover;
    border-radius: 6px;
  }
`

export const TitleSt = styled('span')`
  inline-size: 46px;
  overflow-wrap: break-word;

  font-size: 0.6rem;
`
