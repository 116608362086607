import { useEffect } from 'react'
import { STORAGE_KEYS } from 'src/constants'

export default function AuthPage() {
  useEffect(() => {
    function messageHandler(e: MessageEvent) {
      if ('token' in e.data) {
        localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, e.data.token)
      }
    }

    addEventListener('message', messageHandler)

    return () => removeEventListener('message', messageHandler)
  }, [])

  return null
}
