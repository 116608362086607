export const FacebookIcon = () => (
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.93901 12.154V6.68796H6.80601L7.07201 4.55397H4.93901V3.22097C4.93901 2.62097 5.13901 2.15397 6.00601 2.15397H7.13901V0.220965C6.87201 0.220965 6.20601 0.153965 5.47201 0.153965C5.1009 0.129337 4.72886 0.185425 4.38151 0.318367C4.03415 0.45131 3.71973 0.657949 3.45989 0.924058C3.20006 1.19017 3.00098 1.50942 2.87636 1.85985C2.75174 2.21028 2.70453 2.58355 2.73801 2.95397V4.55397H0.872009V6.68796H2.73901V12.154H4.93901Z"
      fill="#181F32"
    />
  </svg>
)
