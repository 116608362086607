export const CircleChevronTopIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="24 / arrows / circle-chevron-top">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM7.29289 13.2929L8.70711 14.7071L12 11.4142L15.2929 14.7071L16.7071 13.2929L12 8.58579L7.29289 13.2929Z"
        fill="#181F32"
      />
    </g>
  </svg>
)
