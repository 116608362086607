import { Typography } from '@mui/material'
import parse from 'html-react-parser'

import { BottomCardTitle, ImpactsCardsHor, MediaList, PageFooter } from 'src/components'

import { IImpact, IMedia } from 'src/types'
import {
  ContentWrapper,
  ImpactsWrapper,
  NameTitle,
  TitleWrapper,
  ContentSt,
  FooterWrapper,
  FooterDivider,
  DescriptionWrapper,
  StyledScrollBtn
} from './product-card.styled'
import ArrowIcon from 'src/assets/icons/arrow.icon'
import ArrowIconLeft from 'src/assets/icons/arrow.icon-left'
import { useRef } from 'react'
import { useIsTablet } from 'src/hooks'

interface IProps {
  accountHolder: string
  accountImage: string
  productName: string
  productDescription: string
  media: IMedia[]
  impacts: IImpact[]
}

export const Content = ({
  accountHolder,
  accountImage,
  productName,
  productDescription,
  media,
  impacts,
}: IProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const isTablet = useIsTablet()
  const scrollLeft = () => {
    if (scrollContainerRef) {
      scrollContainerRef.current.scrollLeft += -130;
    }
  };
  const scrollRight = () => {
    if (scrollContainerRef) {
      scrollContainerRef.current.scrollLeft += 130;
    }
  };

  return (
    <ContentSt>
      <ContentWrapper>
        <TitleWrapper>
          <BottomCardTitle twitterTitle={productName} title={accountHolder} image={accountImage} />
        </TitleWrapper>
        <NameTitle variant="h2">{productName}</NameTitle>
        <MediaList media={media} />
        <DescriptionWrapper>
          <Typography variant="body1">{parse(productDescription)}</Typography>
        </DescriptionWrapper>
      </ContentWrapper> 
      <ImpactsWrapper>
        <StyledScrollBtn  onClick={scrollLeft} style={{display: isTablet || impacts.length < 5 ? "none" : ""}}>
          <ArrowIconLeft/>          
        </StyledScrollBtn>
        <ImpactsCardsHor items={impacts} ref={scrollContainerRef} withArrows={!(isTablet || impacts.length < 5)}/>
        <StyledScrollBtn  onClick={scrollRight} style={{display: isTablet || impacts.length < 5 ? "none" : ""}}>
          <ArrowIcon />          
        </StyledScrollBtn>
      </ImpactsWrapper>
      <FooterDivider/>
      <FooterWrapper>
        <PageFooter />
      </FooterWrapper>
    </ContentSt>
  )
}
