import { css } from '@emotion/css'
import { styled } from '@mui/material'

export const FooterSt = styled('footer')`
  margin-top: ${({ theme }) => theme.spacing(5)};
  width: 98%;
  display: flex;
  justify-content: flex-end;
`

export const ContentSt = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const versionSt = css`
  display: inline-flex;
  margin-left: auto;
`
