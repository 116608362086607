interface IProps {
  className?: string
}

export const EyeIcon = ({ className }: IProps) => (
  <svg
    width="21"
    height="20"
    className={className}
    viewBox="0 0 21 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="#181F32"
  >
    <g id="24 / basic / eye">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.656 3.33331C13.0035 3.33331 15.2482 4.5255 17.3401 6.48427C18.0538 7.15252 18.6994 7.86594 19.2678 8.57967C19.6108 9.01035 19.8543 9.34914 19.9876 9.55098L20.2842 9.99998L19.9876 10.449C19.8543 10.6508 19.6108 10.9896 19.2678 11.4203C18.6994 12.134 18.0538 12.8474 17.3401 13.5157C15.2482 15.4745 13.0035 16.6666 10.656 16.6666C8.30854 16.6666 6.06384 15.4745 3.97192 13.5157C3.25824 12.8474 2.61259 12.134 2.04422 11.4203C1.70124 10.9896 1.45774 10.6508 1.32441 10.449L1.02783 9.99998L1.32441 9.55098C1.45774 9.34914 1.70124 9.01035 2.04422 8.57967C2.61259 7.86594 3.25824 7.15252 3.97192 6.48427C6.06384 4.5255 8.30854 3.33331 10.656 3.33331ZM17.9047 9.59738C17.3843 8.94391 16.7926 8.29015 16.1435 7.68235C14.3418 5.99529 12.4677 4.99998 10.656 4.99998C8.84425 4.99998 6.97023 5.99529 5.16849 7.68235C4.51938 8.29015 3.92773 8.94391 3.40734 9.59738C3.29495 9.73851 3.19109 9.87316 3.09614 9.99998C3.19109 10.1268 3.29495 10.2614 3.40734 10.4026C3.92773 11.056 4.51938 11.7098 5.16849 12.3176C6.97023 14.0047 8.84425 15 10.656 15C12.4677 15 14.3418 14.0047 16.1435 12.3176C16.7926 11.7098 17.3843 11.056 17.9047 10.4026C18.017 10.2614 18.1209 10.1268 18.2159 9.99998C18.1209 9.87316 18.017 9.73851 17.9047 9.59738ZM7.2134 9.99998C7.2134 11.8409 8.75472 13.3333 10.656 13.3333C12.5573 13.3333 14.0986 11.8409 14.0986 9.99998C14.0986 8.15903 12.5573 6.66665 10.656 6.66665C8.75472 6.66665 7.2134 8.15903 7.2134 9.99998ZM12.3773 9.99998C12.3773 10.9205 11.6067 11.6666 10.656 11.6666C9.70536 11.6666 8.93471 10.9205 8.93471 9.99998C8.93471 9.0795 9.70536 8.33331 10.656 8.33331C11.6067 8.33331 12.3773 9.0795 12.3773 9.99998Z"
        fill="inherit"
      />
    </g>
  </svg>
)
