import { Avatar, Typography, styled } from '@mui/material'

export const ContainerSt = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
`

export const AvatarWrapper = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
`

export const AvatarSt = styled(Avatar)`
  width: 88px;
  height: 88px;
`

export const TitleSt = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};

  font-size: 20px;
`

export const LocationSt = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`

export const LinksWrapper = styled('div')`
  margin-left: auto;
  margin-right: auto;
`
