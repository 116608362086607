import { MouseEventHandler } from 'react'
import { IconButton, Tooltip } from '@mui/material'

import { useStyles } from './styles'

interface IProps {
  handleClick?: MouseEventHandler<HTMLButtonElement>
  iconAlt: string
  icon: string
  title: string
  width?: number
}

export const HoverIcon = ({ handleClick, iconAlt, icon, title, width = 30 }: IProps) => {
  const classes = useStyles()

  return (
    <>
      <Tooltip title={title} PopperProps={{ className: classes.popper }}>
        <IconButton onClick={handleClick} className={classes.selectionButton}>
          <img
            alt={iconAlt}
            src={icon}
            className={classes.selectionIcon}
            style={{ width, height: width }}
          />
        </IconButton>
      </Tooltip>
    </>
  )
}
