import { Outlet } from 'react-router-dom'

import { useAuthentication, useInjectCss } from 'src/hooks'

export const RoutesWrapper = () => {
  useAuthentication()
  useInjectCss()

  return <Outlet />
}
