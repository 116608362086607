import { IconButton } from '@mui/material'
import { TwitterShareButton } from 'react-share'

import { TwitterIcon } from 'src/assets/icons'
import { iconSt } from './share-button.styled'
import { theme } from 'src/providers/theme-provider/theme.provider'

export const TwitterShare = ({ title }) => {
  return (
    <TwitterShareButton title={title} hashtags={['trackgood']} url={window.location.href}>
      <IconButton component="div" style={{backgroundColor: theme.palette.primary.main}}>
        <TwitterIcon className={iconSt}/>
      </IconButton>
    </TwitterShareButton>
  )
}
