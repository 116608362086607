import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface IProps {
  initOpen?: boolean
  openNavigation?: string
  closeNavigation?: string
}

export const useDrawer = ({ initOpen, openNavigation, closeNavigation }: IProps) => {
  const navigate = useNavigate()

  const [open, setOpen] = useState(!!initOpen)

  const handleOpen = () => {
    setOpen(true)
    navigate(openNavigation)
  }

  const handleClose = () => {
    setOpen(false)
    navigate(closeNavigation)
  }

  return {
    open,
    handleOpen,
    handleClose,
  }
}
