export const ROUTES = {
  AUTH: 'auth',

  LANGUAGE: ':lng',
  PRODUCTS: 'product/:productId',
  PRODUCT: 'product',
  IMPACTS: 'impacts',
  ACCOUNT: 'account',
  JOURNEY: 'journey',
  JOURNEY_DETAILED: 'journey/details',
}
