import { Typography } from '@mui/material'
import { AccountHolder, AvatarSt, DescriptionHolder, Divider, WrapperSt } from './step-company.styled'

interface IProps {
  name?: string
  image: string
  location_name?: string
  account_holder?: string
  landing_page_show?: boolean
  accImg?: string
  account_approved?: boolean
  email?: string
}

export const StepInfo = ({ name, image, location_name, accImg, landing_page_show, account_holder, account_approved, email }: IProps) => {
  return (
    <WrapperSt>
      <AvatarSt src={image} alt={name} variant="square" />
      <DescriptionHolder isLeft>
        {name && <Typography sx={{ fontSize: 18, fontWeight: '700' }}>{name}</Typography>}
        {location_name && <Typography variant="body2">{location_name}</Typography>}
      </DescriptionHolder>
      <Divider />
      {landing_page_show && account_approved &&
          <AccountHolder>
            <DescriptionHolder>
              <Typography sx={{ fontSize: 18, fontWeight: '700' }}>{account_holder}</Typography>
              <Typography variant="body2">{email}</Typography>
            </DescriptionHolder>
            <AvatarSt src={accImg} alt={name} variant="square" />
          </AccountHolder>}
    </WrapperSt>
  )
}
