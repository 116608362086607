import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  selectionIcon: {
    objectFit: 'cover',
  },
  selectionButton: {
    '&&': {
      padding: 1,

      borderRadius: 6,
      overflow: 'hidden',
    },
  },
  textWrapper: {
    padding: '8px',
  },
  popper: {
    '&&': {
      '& div': {
        borderRadius: '4px',
      },
    },
  },
}))
