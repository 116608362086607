import { useMutation } from '@tanstack/react-query'
import { STORAGE_KEYS } from 'src/constants'
import { authServie } from 'src/services'

export const useRefreshToken = () => {
  return useMutation(
    () => {
      const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)
      if (!refreshToken) {
        return null
      }

      return authServie.refreshToken(refreshToken)
    },
    {
      onSuccess(data) {
        if (!data) {
          localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN)
          localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
        }

        if (data.accessToken) {
          localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, data.accessToken)
        }
      },
    },
  )
}
