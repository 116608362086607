interface IProps {
  className?: string
}

export const ShareIcon = ({ className }: IProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="black"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.125 9.99997V3.38386L10.1831 5.44191L11.0669 4.55803L7.5 0.991089L3.93306 4.55803L4.81694 5.44191L6.875 3.38386V9.99997H8.125ZM13.125 12.5V6.87497H11.875V12.5H3.125V6.87497H1.875V12.5C1.875 13.1903 2.43464 13.75 3.125 13.75H11.875C12.5654 13.75 13.125 13.1903 13.125 12.5Z"
        fill="inherit"
      />
    </svg>
  )
}
