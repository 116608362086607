import { css } from '@emotion/css'
import { IconButton, styled } from '@mui/material'

export const ButtonSt = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
    border: ${({ theme }) => theme.spacing(0.1)} solid ${({ theme }) => theme.palette.primary.contrastText};

    & svg {
      width: 20px;
      height: auto;
    }
  }
`

export const iconSt = css`
  padding: 8px;
  width: 16px;
  height: 16px;
`
