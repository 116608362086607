import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import 'typeface-noto-sans'
import './index.css'

import { App } from './App'
import { AppProviders } from './providers'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>,
)
