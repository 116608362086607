import { useMutation } from '@tanstack/react-query'

import { STORAGE_KEYS } from 'src/constants'
import { impactService } from 'src/services'

export const useUnsetImpactLike = () => {
  return useMutation((impactId: string) => {
    const guestId = localStorage.getItem(STORAGE_KEYS.GUEST_ID)

    if (!guestId) return null

    return impactService.unsetImpactLike(impactId, guestId)
  })
}
