import { IUnSdg } from 'src/types'

import { HoverIcon } from '../hover-icon'
import { UnSdgList as UnSdgListSt } from '../impacts-cards-hor/impact-cards-hor.styled'

interface IProps {
  unSdg: IUnSdg[]
}

export const UnSdgList = ({ unSdg }: IProps) => {
  return (
    <UnSdgListSt>
      {unSdg.map((item) => (
        <HoverIcon
          width={50}
          key={item.id}
          title={item.name}
          icon={item.image_medium || item.image}
          iconAlt={item.name}
        />
      ))}
    </UnSdgListSt>
  )
}
