import { IMarker } from 'src/types'

export function fitMapWithinMarkers(map: google.maps.Map, markers: IMarker[]) {
  const bounds = new window.google.maps.LatLngBounds()

  markers.forEach((marker) => {
    bounds.extend({ lat: marker.latitude, lng: marker.longitude })
  })

  map.fitBounds(bounds)
}
