import { QueryClient, QueryClientProvider as Provider } from '@tanstack/react-query'
import { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const QueryClientProvider = ({ children }: IProps) => (
  <Provider client={queryClient}>{children}</Provider>
)
