import { css } from '@emotion/css'
import { IconButton, styled } from '@mui/material'
import BurgerIcon from '@mui/icons-material/Menu'

export const menuWrapper = css`
  top: 20px;
  left: 20px;
  position: absolute;
  z-index: 20;
`

export const MenuIconButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.common.white};
`

export const MenuIcon = styled(BurgerIcon)`
  && {
    border-radius: 50%;
    padding: ${({ theme }) => theme.spacing(0.6)};
    background-color: rgba(0, 0, 0, 0.4);

    width: 30px;
    height: 30px;
    fill: #fff;
  }
`
