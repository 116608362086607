import { Avatar, styled } from '@mui/material'
import { css } from '@emotion/css'

export const ImageSt = styled('img')`
  width: 100%;
  height: 54lvh;

  object-fit: cover;
`

export const VideoSt = styled('video')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`

export const ImageWrapper = styled('div')`
  justify-content: center;
  text-align: center;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    position: sticky;
    z-index: 0;
    left: 0;
    top: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
  }
`

export const List = styled('ul')`
  position: absolute;
  left: 16px;
  width: calc(100% - 32px);

  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    bottom: 24px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    bottom: 10px;
  }
`

export const ListImage = styled(Avatar)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 40px;
    height: 40px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 44px;
    height: 44px;
  }

  border-radius: 6px;
`

export const mediaCarousel = css`
  & > div > div > div > div {
    position: static !important;
  }
`
