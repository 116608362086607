import { useParams } from 'react-router-dom'
import { useSwiper } from 'swiper/react'
import { useTranslation } from 'react-i18next'

import { PreviousPage, PageWrapper, ImpactsCardsVer, PageContainer } from 'src/components'
import { useProductImpacts } from 'src/hooks'

import { EType, useDrawerer } from 'src/contexts/modal-drawer.context'

export default function ImpactsPage() {
  const [, i18n] = useTranslation()
  const { productId } = useParams()
  const swiper = useSwiper()
  const { data } = useProductImpacts(productId, i18n.language)
  const impacts = data?.impacts || []
  const drawerer = useDrawerer()

  const handlePrevious = () => {
    drawerer.setActive(EType.JOURNEY)
    swiper.slidePrev()
  }

  return (
    <PageWrapper>
      <PageContainer>
        <PreviousPage onClick={handlePrevious} absolute/>
        <ImpactsCardsVer items={impacts} />
      </PageContainer>
    </PageWrapper>
  )
}
