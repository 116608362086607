import { useQuery } from '@tanstack/react-query'

import { QUERY_KEYS } from 'src/constants'
import { productService } from 'src/services'

export const useAccountProducts = (accountId: string, enabled = true) => {
  return useQuery(
    [QUERY_KEYS.GET_ACCOUNT_PRODUCTS, accountId],
    () => productService.getAccountProducts(accountId),
    { enabled },
  )
}
