import { ReactNode } from 'react'

import { Container } from './page-container.styled'
import { PageFooter } from '../page-footer'

interface IProps {
  children: ReactNode
}

export const PageContainer = ({ children }: IProps) => {
  return (
    <Container>
      {children}
      <PageFooter />
    </Container>
  )
}
