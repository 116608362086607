import { Modal, Paper, styled } from '@mui/material'

export const ModalSt = styled(Modal)`
  z-index: 2000;

  display: flex;
  flex-direction: column;
  heigth: 100%;
`

export const ModalPaper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;

  outline: none;

  padding: ${({ theme }) => theme.spacing(2.5)};
  max-height: calc(100% - 100px);
  height: min-content;

  flex: 1 1 auto;

  overflow: auto;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: calc(100% - 60px);
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 800px;
    min-width: 400px;
  }
`
