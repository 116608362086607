import { Button, styled } from '@mui/material'

export const LogButton = styled(Button)`
  border-radius: 15px;
  box-shadow: 0px 3px 10px 0px #eee;

  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;

  && {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`

export const SuspendedItem = styled('div')`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
`
export const UnsuspendedItem = styled('div')`
  display: flex;
`

export const SuspendedBlock = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 10px;
`
export const NestedImage = styled('div')<{ im: string }>`
  background-image: url('${(props) => props.im}');
  height: 20vh;
  width: 100%;
  background-size: cover;
  background-position: center;
`

export const Code = styled('p')`
  color: #181f32;
  font-family: Noto Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
