import { CardContent, Chip, styled } from '@mui/material'

export const CardContentSt = styled(CardContent)`
  padding: 0;

  border-radius: 15px;
  box-shadow: 0px 3px 10px 0px ${({ theme }) => theme.palette.grey.A200};
`

export const UpperContentSt = styled('div')`
  padding: ${({ theme }) => theme.spacing(1.75)} ${({ theme }) => theme.spacing(2.5)};
`

export const CarouselWrapper = styled('div')``

export const LowerContentSt = styled('div')`
  padding: ${({ theme }) => theme.spacing(1.25)} ${({ theme }) => theme.spacing(2.5)}
    ${({ theme }) => theme.spacing(1.75)};
`

export const CardChip = styled(Chip)`
  margin-bottom: ${({ theme }) => theme.spacing(3.5)};

  min-width: 120px;
  height: 30px;

  border-radius: 20px;
  background-color: ${({ theme }) => theme.palette.grey.A200};

  font-size: 14px;
  font-weight: 600;
  line-height: 1.33;
`

export const InfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`

export const StepImpactsList = styled('ul')`
  margin-left: 70px;

  display: flex;
  gap: ${({ theme }) => theme.spacing(1.25)};
`
