import { Avatar, styled } from '@mui/material'

export const WrapperSt = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.25)};
`

export const AvatarSt = styled(Avatar)`
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.palette.background.paper};

  border-radius: 6px;
  width: 60px;
  height: 60px;
`

export const AvatarAccount = styled(Avatar)`
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.palette.background.paper};

  border-radius: 50%;
  width: 60px;
  height: 60px;
`

export const AccountHolder = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Divider = styled('span')`
  flex-grow: 1;
`

export const DescriptionHolder = styled('div')<{isLeft?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.isLeft ? 'start' : 'end'};
`