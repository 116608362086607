import { useRef, useState } from 'react'

import { MenuPopUp } from './menu-pop-up.component'
import { MenuIcon, menuWrapper, MenuIconButton } from './menu-burger.styled'

export const MenuBurger = () => {
  const [toggle, setToggle] = useState(false)
  const anchorEl = useRef<HTMLButtonElement | null>(null)

  const handleToggle = () => {
    setToggle(!toggle)
  }

  return (
    <div className={menuWrapper}>
      <MenuIconButton ref={anchorEl} onClick={handleToggle}>
        <MenuIcon />
      </MenuIconButton>
      <MenuPopUp anchorEl={anchorEl.current} open={toggle} handleClose={handleToggle} />
    </div>
  )
}
