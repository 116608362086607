import { Typography, styled } from '@mui/material'

export const ContentSt = styled('div')<{ isDesktop?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme }) => theme.spacing(2.25)};
  }
`

export const ContentWrapper = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(4.25)} ${theme.spacing(4.25)} ${theme.spacing(0)};
  margin-bottom: ${theme.spacing(2.25)};
`,
)

export const DescriptionWrapper = styled('div')(
  () => ``
)

export const TitleWrapper = styled('div')(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(3)};
  display: flex;
  justify-content: center;
`,
)

export const NameTitle = styled(Typography)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(1.25)};
`,
)

export const ImpactsWrapper = styled('div')`
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
`
export const StyledScrollBtn = styled('div')`
    cursor: pointer;
  `

export const FooterWrapper = styled('div')`
  margin: 0 ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
`

export const MediaListSt = styled('ul')`
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(0.5)};
`
export const FooterDivider = styled('div')`
  flex-grow: 1;
`
