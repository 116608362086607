import Carousel from 'react-material-ui-carousel'

import { IMedia } from 'src/types'

import { ImageSt, VideoSt, mediaCarousel } from './product-image.styled'

interface IProps {
  media: Array<IMedia>
}

export const MediaCarousel = ({ media }: IProps) => {
  return (
    <>
      <Carousel
        stopAutoPlayOnHover
        animation="slide"
        autoPlay
        height={'54lvh'}
        indicators={false}
        navButtonsAlwaysVisible={false}
        duration={500}
        className={mediaCarousel}
      >
        {media.map((item) => {
          if (item.type.startsWith('video')) {
            return <VideoSt key={item.id} src={item.link} loop muted autoPlay />
          }

          return <ImageSt loading="eager" key={item.id} alt={item.title} src={item.link} />
        })}
      </Carousel>
    </>
  )
}
