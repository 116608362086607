import { Outlet } from 'react-router-dom'
import { ScanFAB } from 'src/components'

export default function Home() {
  return (
    <>
      <Outlet />
      <ScanFAB />
    </>
  )
}
