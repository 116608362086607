import { MutableRefObject, useState } from 'react'
import { MarkerF, OverlayViewF, OverlayView } from '@react-google-maps/api'
import MapMarkerDark from 'src/assets/files/map-marker-dark.svg'
import MapMarkerLight from 'src/assets/files/map-marker-light.svg'
import { IMarker } from 'src/types'
import { StepTextHolder, StepTextHolderButton, StepTextHolderLocationName, StepTextHolderName } from './custom-marker.styled'
import { useAnimNavContext } from 'src/hooks'

interface IProps {
    satelliteView?: boolean
    marker: IMarker,
    stepName: string[],
    stepLocation: string
    scrollToRef?: MutableRefObject<HTMLSpanElement>
  }

export const CustomMarker = (props: IProps) => {
    const {satelliteView, marker, stepLocation, stepName, scrollToRef} = props
    const { journeyDetailsDrawer } = useAnimNavContext()
    const [markOpened, setMarkOpened] = useState<boolean>(false)
    const [cursorInStep, setCursorInStep] = useState<boolean>(false)
    const [isMobile, ] = useState<boolean>(window.innerWidth < 770)

    const handleClick = () => {
        if(isMobile){
            setMarkOpened(false)
            journeyDetailsDrawer.handleOpen()  
        } else {
            scrollToRef.current.scrollIntoView({behavior: 'smooth'});
        }  
    };
    return (
        <OverlayViewF
            position={{lat: marker.latitude, lng: marker.longitude}}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({x: -width / 2, y: -height / 2})}
        >
            <>  
                <MarkerF
                    icon={satelliteView ? MapMarkerLight : MapMarkerDark}
                    key={marker.latitude + marker.longitude}
                    position={{
                        lat: marker.latitude,
                        lng: marker.longitude,
                    }}
                    onMouseOver={() => {setMarkOpened(true)}}
                    onMouseOut={() => {cursorInStep ? null : setMarkOpened(false)}}
                    onMouseDown={() => {isMobile && setMarkOpened(true)}}
                />
                {markOpened && 
                <StepTextHolder 
                    onMouseEnter={() => {setCursorInStep(true)}}
                    onMouseLeave={() => {!isMobile && setCursorInStep(false); !isMobile && setMarkOpened(false)}}
                >
                    <StepTextHolderLocationName>{stepLocation}</StepTextHolderLocationName>
                    <StepTextHolderName>
                        {stepName.map((item:string) => {
                            return (<>{item}<br/></>)
                        })}
                    </StepTextHolderName>
                    <StepTextHolderButton onClick={handleClick}>
                        More
                    </StepTextHolderButton>
                </StepTextHolder>}
            </>

      </OverlayViewF>        
    )
}
