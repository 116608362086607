import { useSwiper } from 'swiper/react'

import { IMedia, IUnSdg } from 'src/types'

import { MenuBurger } from '../menu-burger'
import { NextPage } from '../navigation'
import { UnSdgList } from './un-sdg-list.component'
import { ImageSt, ImageWrapper } from './product-image.styled'
import { MediaCarousel } from './media-carousel.component'
import { useNavigateWithParams } from 'src/hooks'
import { EType, useDrawerer } from 'src/contexts/modal-drawer.context'

interface IProps {
  name: string
  image: string
  media: IMedia[]
  unSdg: IUnSdg[]
}

export const ProductImage = ({ name, image, unSdg, media }: IProps) => {
  const swiper = useSwiper()
  const navigate = useNavigateWithParams()
  const drawerer = useDrawerer()

  const handleNext = () => {
    drawerer.setActive(EType.JOURNEY)
    navigate('journey')
    swiper.slideNext()
  }

  return (
    <ImageWrapper>
      {media.length ? <MediaCarousel media={media} /> : <ImageSt alt={name} src={image} />}
      <UnSdgList unSdg={unSdg} />
      <MenuBurger />
      <NextPage onClick={handleNext} />
    </ImageWrapper>
  )
}
