import axios from 'axios'

import { BASE_API_URL } from 'src/constants'

import { HttpService } from './http.service'

class StylesService extends HttpService {
  constructor() {
    super(axios, BASE_API_URL)
  }

  async getAccountStyles(productId: string) {
    return this.get<string | null>(`accounts/styles/product/${productId}`)
  }
}

export const stylesService = new StylesService()
export default StylesService
