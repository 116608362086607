import { EUserRoles } from './user.type'

export interface IRefreshTokenResponse {
  accessToken: string
  id: string
}

export interface IUserAuthResponse {
  message: string
  roles: EUserRoles[]
  id: string
}

export interface ICurrentUserResponse {
  id: string
  roles: EUserRoles[]
  email: string
  name: string | null
  image: string
  image_small: string
  image_medium: string
  accountRoles: EAccountRoles[] | null
}

export enum EAccountRoles {
  owner = 'owner',
  admin = 'admin',
  user = 'user',
  scanner = 'scanner',
}
