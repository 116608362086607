import { IUnSdg } from 'src/types'

import { List, ListImage } from './product-image.styled'

interface IProps {
  unSdg: IUnSdg[]
}

export const UnSdgList = ({ unSdg }: IProps) => {
  return (
    <List>
      {unSdg.map((item) => (
        <li key={item.id}>
          <ListImage src={item.image_medium || item.image} alt={item.name} />
        </li>
      ))}
    </List>
  )
}
