import {
  LinkedinIcon,
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WebsiteIcon,
} from 'src/assets/icons'
import { IAccount } from 'src/types'

import { LinkButton } from './link-button.component'
import { LinksList } from './account-links.styled'

type IProps = {
  socials: IAccount['socials']
  email?: string
  website?: string
}

export const AccountLinks = ({ email, website, socials }: IProps) => {
  const { facebook, instagram, x, linkedin } = socials
  return (
    <LinksList>
      {facebook && (
        <LinkButton href={facebook}>
          <FacebookIcon />
        </LinkButton>
      )}
      {instagram && (
        <LinkButton href={instagram}>
          <InstagramIcon />
        </LinkButton>
      )}
      {x && (
        <LinkButton href={x}>
          <TwitterIcon />
        </LinkButton>
      )}
      {linkedin && (
        <LinkButton href={linkedin}>
          <LinkedinIcon />
        </LinkButton>
      )}
      {website && (
        <LinkButton href={`//${website}`}>
          <WebsiteIcon />
        </LinkButton>
      )}
      {email && (
        <LinkButton href={`mailto:${email}`}>
          <EmailIcon />
        </LinkButton>
      )}
    </LinksList>
  )
}
