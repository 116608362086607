import axios from 'axios'

import { HttpService } from './http.service'
import { ILocalesResponse } from 'src/types'

class LocaleService extends HttpService {
  constructor() {
    super(axios)
  }

  getProductLocales(productId: string) {
    return this.get<ILocalesResponse>(`product/${productId}/locales`)
  }
}

export const localeService = new LocaleService()
