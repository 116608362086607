import { useTranslation } from 'react-i18next'

import { IMedia, IProduct } from 'src/types'

import { MediaList } from '../media-list'
import { ProductsList } from './products-list.component'
import { DetailsContainer, DetailsDescription, DetailsTitle } from './account-details.styled'

interface IProps {
  description: string
  media: IMedia[]
  products: IProduct[]
}

export const AccountDetails = ({ description, media, products }: IProps) => {
  const [t] = useTranslation()

  return (
    <DetailsContainer>
      <DetailsTitle variant="subtitle1">{t('about-us-label')}</DetailsTitle>
      <DetailsDescription variant="body2">{description}</DetailsDescription>
      <MediaList media={media} />
      <ProductsList products={products} />
    </DetailsContainer>
  )
}
