import { Avatar, Typography } from '@mui/material'

import { ShareButton, TwitterShare } from 'src/components'

import { imgSt, titleDivider, titleWrapper, wrapper, wrapperMobile } from './bottom-card-title.styled'
import { useNavigateWithParams } from 'src/hooks/common/use-navigate-with-params.hook'

interface IProps {
  title: string
  image: string
  twitterTitle: string
}

export const BottomCardTitle = ({ title, image, twitterTitle }: IProps) => {
  const isMobile = innerWidth < 770
  const customNav = useNavigateWithParams()
  const handleNavigate = (to: string) => () => customNav(to)

  return (
    <div className={isMobile ? wrapperMobile : wrapper }>
      <div className={titleWrapper} onClick={handleNavigate('account')}>
        <Avatar className={imgSt} alt={title} src={image} />
        <Typography variant="h5">{title}</Typography>
      </div>
      <span className={titleDivider}/>
      <ShareButton />
      <TwitterShare title={twitterTitle} />
    </div>
  )
}
