import { ShareIcon } from 'src/assets/icons'

import { ButtonSt } from './share-button.styled'

export const ShareButton = () => {
  const handleShare = () => {
    navigator.share && navigator.share({ url: window.location.href })
  }

  return navigator.share ? (
    <ButtonSt size="large" onClick={handleShare}>
      <ShareIcon />
    </ButtonSt>
  ) : null
}
