export const UserIcon = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="24 / basic / user">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82812 14.375C4.03117 14.375 0.953125 11.297 0.953125 7.5C0.953125 3.70304 4.03117 0.625 7.82812 0.625C11.6251 0.625 14.7031 3.70304 14.7031 7.5C14.7031 11.297 11.6251 14.375 7.82812 14.375ZM12.4528 10.703C13.0835 9.79399 13.4532 8.69017 13.4532 7.50001C13.4532 4.39341 10.9348 1.87501 7.82816 1.87501C4.72156 1.87501 2.20316 4.39341 2.20316 7.50001C2.20316 8.69013 2.57276 9.79393 3.20345 10.7029C3.90687 9.77379 5.55976 9.37501 7.8281 9.37501C10.0965 9.37501 11.7494 9.77381 12.4528 10.703ZM11.556 11.7124C11.3732 11.0546 10.059 10.625 7.8281 10.625C5.59729 10.625 4.28316 11.0546 4.10019 11.7123C5.09268 12.5914 6.3981 13.125 7.82816 13.125C9.25818 13.125 10.5636 12.5914 11.556 11.7124ZM7.82817 3.75001C6.31511 3.75001 5.32817 4.84736 5.32817 6.25001C5.32817 8.39212 6.42871 9.37501 7.82817 9.37501C9.21448 9.37501 10.3282 8.42477 10.3282 6.37501C10.3282 4.951 9.33704 3.75001 7.82817 3.75001ZM6.57807 6.24999C6.57807 7.66828 7.08945 8.12499 7.82807 8.12499C8.56414 8.12499 9.07807 7.68649 9.07807 6.37499C9.07807 5.594 8.58786 4.99999 7.82807 4.99999C7.03666 4.99999 6.57807 5.50988 6.57807 6.24999Z"
        fill="#181F32"
      />
    </g>
  </svg>
)
