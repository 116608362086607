import styled from '@emotion/styled'

export const StepTextHolder = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  padding: 5px;
  background-color: #fff;
`
export const StepTextHolderButton = styled.button`
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 3px;
  margin-top: 4px;
`

export const StepTextHolderLocationName = styled.h3`
  margin-bottom: 6px;
`

export const StepTextHolderName = styled.p`
  font-size: 9px;
`
