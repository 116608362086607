import { IImpact } from 'src/types'

import { ImpactCard } from './impact-card.component'
import { List } from './impact-cards-hor.styled'
import { forwardRef } from 'react'
interface IProps {
  items: IImpact[]
  withArrows?: boolean
}

export const ImpactsCardsHor = forwardRef<HTMLDivElement, IProps>(({ items, withArrows }, ref) => {
  return (
    <List ref={ref} withArrows={withArrows}>
      {items.map((item) => (
        <ImpactCard
          id={item.id}
          key={item.id}
          title={item.quantity}
          subtitle={item.name}
          image={item.image_small || item.image}
          unSdg={item.un_sdg}
        />
      ))}
    </List>
  );
});
