import { styled } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'

export const SwiperSt = styled(Swiper)`
  height: 100lvh;
`

export const SwiperSlideSt = styled(SwiperSlide) <{ constantOverflow?: boolean }>`
  overflow-y: ${({ constantOverflow }) => (constantOverflow ? 'auto' : 'hidden')};

  ${({ theme }) => theme.breakpoints.up('md')} {
    overflow-y: auto;
  }
`
