import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'src/constants'
import { accountService } from 'src/services'

export const useAccount = (accountId: string, locale: string, enabled = true) => {
  return useQuery(
    [QUERY_KEYS.GET_ACCOUNT, accountId, locale],
    () => accountService.getAccount(accountId, locale),

    {
      enabled,
      keepPreviousData: true,
    },
  )
}
