import { styled, Fab } from '@mui/material'

export const FabSt = styled(Fab)`
  && {
    position: fixed;
    bottom: 16px;
    right: 50%;
    transform: translateX(50%);
    z-index: 2299;

    height: 36px;
    min-width: 92px;
    padding: ${({ theme }) => theme.spacing(0.75, 2)};

    border-radius: 4px;

    line-height: 1.75;
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    font-weight: 500;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.background.default};

    & > svg {
      margin-left: -4px;
      margin-right: 8px;

      fill: ${({ theme }) => theme.palette.background.default};
    }
  }
`
