interface IProps {
  className?: string
}

export const HeartFilledIcon = ({ className }: IProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="#FF005C"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.18916 1.68505C8.85774 1.35779 9.44097 1.25 10.3458 1.25001C12.6612 1.2596 14.375 3.2124 14.375 5.69992C14.375 7.5986 13.3164 9.43268 11.3445 11.2062C10.3094 12.1371 8.98792 13.0583 8.0415 13.5484L7.5 13.8288L6.9585 13.5484C6.01208 13.0583 4.69056 12.1371 3.65552 11.2062C1.68364 9.43268 0.625 7.5986 0.625 5.69992C0.625 3.18579 2.32277 1.25 4.65909 1.25C5.53142 1.25 6.14457 1.36798 6.82613 1.70508C7.06342 1.82245 7.28637 1.96166 7.49373 2.12235C7.7094 1.95212 7.94163 1.80621 8.18916 1.68505Z"
      fill="inherit"
    />
  </svg>
)
