import { Avatar, Typography, styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const List = styled('div')<{withArrows?: boolean}>(
  ({ theme, withArrows }) => `
  padding: ${theme.spacing(1.25)} ${!withArrows ? '30px' : '0'} ${theme.spacing(1.25)};
  width: 85vw;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  display: flex;
  gap: ${theme.spacing(2.5)};
`,
)

export const Item = styled('div')(`
  height: auto;

  transition-duration: 200ms;
  &:hover {
    filter: brightness(90%);
    scale: 0.95
  }
`
)

export const LinkSt = styled(Link)(
  ({ theme }) => `
  width: 120px;
  height: 160px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 7px;
  background: ${theme.palette.background.default};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);

  overflow: hidden;

  text-decoration: none;
  color: inherit;
`,
)

export const ItemBottom = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(1.25)};

  padding-bottom: ${theme.spacing(1)};
  padding-left: ${theme.spacing(1)};
  padding-right: ${theme.spacing(1)};
`,
)

export const ItemImage = styled(Avatar)(
  () => `
  width: 100%;
  background-size: cover;
  height: 110px;
`,
)
export const ItemText = styled(Typography)(
  () => `
  text-overflow: ellipsis;
  text-align: center;
  word-break: break-word;
`,
)

export const UnSdgList = styled('div')(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing(0.5)};
  flex-wrap: wrap;
  align-items: center;
`,
)
