import axios from 'axios'

import { HttpService } from './http.service'

import { IAccount } from 'src/types'

class AccountService extends HttpService {
  constructor() {
    super(axios)
  }

  getAccounts() {
    return this.get<IAccount[]>('account')
  }

  getAccount(id: string, locale: string) {
    return this.get<IAccount>(`account/${id}/${locale}`)
  }
}

export const accountService = new AccountService()
