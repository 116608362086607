import LanguageDetector from 'i18next-browser-languagedetector'
import { STORAGE_KEYS } from 'src/constants'

const urlLngDetector = {
  name: 'urlDetector',

  lookup(options) {
    const storageLng = localStorage.getItem(STORAGE_KEYS.I18N_LNG)
    const { fallbackLng = storageLng || 'en' } = options

    const path = window.location.pathname.split('/')

    const possibleLocale = path[1]
    if (possibleLocale === 'product' || possibleLocale.length > 5) {
      window.location.replace(`/${fallbackLng}${window.location.pathname}`)
      return fallbackLng
    }

    return possibleLocale
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(urlLngDetector)

export default languageDetector
