import { useState } from 'react'
import { Collapse, Divider, IconButton, List, ListItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { BcTxButton } from 'src/components'
import {
  CircleChevronBottomIcon,
  CircleChevronTopIcon,
  HashIcon,
  ImageIcon,
  LocationIcon,
  TimeIcon,
  UserIcon,
} from 'src/assets/icons'

import { UncollapsedItem } from './uncollapsed-item.component'
import { CollapsedList } from './collapsed-list.component'
import { IMedia, IScanner } from 'src/types'

interface IProps {
  media: IMedia[]
  timestamp: string
  stepLocation: string
  latitude: number
  longitude: number
  hash: string
  isLast: boolean
  scanner: IScanner | null
}

export const TransactionItem = (props: IProps) => {
  const [t] = useTranslation()
  const [toggle, setToggle] = useState(false)

  const handleToggle = () => setToggle(!toggle)

  const mediaLength = props.media?.length || 0
  return (
    <>
      <ListItem disableGutters alignItems={toggle ? undefined : 'flex-start'}>
        <IconButton onClick={handleToggle}>
          {toggle ? <CircleChevronTopIcon /> : <CircleChevronBottomIcon />}
        </IconButton>
        {toggle ? (
          <BcTxButton hash={props.hash} />
        ) : (
          <List disablePadding>
            <UncollapsedItem
              icon={<TimeIcon />}
              title={format(new Date(props.timestamp), 'dd LLLL yyyy')}
            />
            {props.scanner && (
              <UncollapsedItem
                icon={<UserIcon />}
                title={props.scanner.name || t('scanner-header-title')}
              />
            )}
            <UncollapsedItem icon={<LocationIcon />} title={props.stepLocation} />
            {Boolean(mediaLength) && (
              <UncollapsedItem
                icon={<ImageIcon />}
                title={`${mediaLength} image${mediaLength > 1 ? 's' : ''}`}
              />
            )}
            <UncollapsedItem icon={<HashIcon />} title={props.hash} />
          </List>
        )}
      </ListItem>
      <Collapse in={toggle} timeout="auto">
        <CollapsedList {...props} />
      </Collapse>
      {!props.isLast && <Divider />}
    </>
  )
}
