interface IProps {
  className?: string
}

export const GpsLogIcon = ({ className }: IProps) => (
  <svg
    fill="#181F32"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.40109 8.18182H0V6.81818H1.40109C1.71551 3.9739 3.9739 1.71551 6.81818 1.40109V0H8.18182V1.40109C11.0261 1.71551 13.2845 3.9739 13.5989 6.81818H15V8.18182H13.5989C13.2845 11.0261 11.0261 13.2845 8.18182 13.5989V15H6.81818V13.5989C3.9739 13.2845 1.71551 11.0261 1.40109 8.18182ZM7.50027 2.72717C4.86436 2.72717 2.72754 4.864 2.72754 7.4999C2.72754 10.1358 4.86436 12.2726 7.50027 12.2726C10.1362 12.2726 12.273 10.1358 12.273 7.4999C12.273 4.864 10.1362 2.72717 7.50027 2.72717ZM10.2274 7.5001C10.2274 5.99387 9.00633 4.77283 7.5001 4.77283C5.99387 4.77283 4.77283 5.99387 4.77283 7.5001C4.77283 9.00633 5.99387 10.2274 7.5001 10.2274C9.00633 10.2274 10.2274 9.00633 10.2274 7.5001Z"
      fill="inherit"
    />
  </svg>
)
