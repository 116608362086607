import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import moment from 'moment'

export const LngWrapper = () => {
  const { i18n } = useTranslation()
  moment.locale(i18n.language)

  return <Outlet />
}
