import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useProduct } from 'src/hooks'

import { Loader, PageWrapper, ProductCard, ProductImage } from 'src/components'

export default function ProductPage() {
  const [, i18n] = useTranslation()
  const { productId } = useParams()
  const { data: product } = useProduct(productId!, i18n.language)
  console.log(product)

  if (!product) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  if(product.override_url){
    window.location.replace(product.override_url);
  }    


  return (
    <PageWrapper isJourney>
      <ProductImage
        name={product.name}
        image={product.image}
        media={product.mainMedia}
        unSdg={product.un_sdg}
      />
      <ProductCard product={product} />
    </PageWrapper>
  )
}
