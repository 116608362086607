import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { QUERY_KEYS } from 'src/constants'
import { stylesService } from 'src/services'

export const useAccountStyles = () => {
  const { productId } = useParams()

  return useQuery(
    [QUERY_KEYS.GET_ACCOUNT_STYLES, productId],
    () => (productId ? stylesService.getAccountStyles(productId) : null),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )
}
