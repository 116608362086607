import JourneyDetailed from 'src/pages/journey-detailed'
import { BottomCard } from 'src/components'
import { useAnimNavContext, useIsTablet } from 'src/hooks'
import { IStep } from 'src/types'

import { JourneySteps } from '../journey-steps'
import { ContentWrapper } from './journey-card.styled'
import { Title } from './title.component'
import { MutableRefObject } from 'react'
import { EType, useDrawerer } from 'src/contexts/modal-drawer.context'

interface IProps {
  steps: IStep[]
  journeyName: string
  topRef?: MutableRefObject<HTMLSpanElement>
}

export const JourneyCard = (props: IProps) => {
  const {steps, journeyName, topRef} = props
  const { journeyDetailsDrawer } = useAnimNavContext()
  const isTablet = useIsTablet()

  if (!isTablet) {
    return <JourneyDetailed topRef={topRef}/>
  }

  const drawerer = useDrawerer()
  return (
    <BottomCard
      enabled={drawerer.active === EType.JOURNEY}
      handleOpen={journeyDetailsDrawer.handleOpen}
      handleClose={journeyDetailsDrawer.handleClose}
      open={journeyDetailsDrawer.open}
      closedNode={
        <ContentWrapper>
          <Title journeyName={journeyName} />
          <JourneySteps steps={steps} topRef={topRef}/>
        </ContentWrapper>
      }
    >
      <JourneyDetailed topRef={topRef}/>
    </BottomCard>
  )
}
