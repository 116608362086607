import { IconButton, styled } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

export const LeftButton = styled(IconButton) <{ absolute?: boolean }>`
  position: ${(props) => (props.absolute ? 'absolute' : 'static')};
  top: 20px;
  left: 20px;
  z-index: 20;

  padding: ${({ theme }) => theme.spacing(1.25)};
  color: ${({ theme }) => theme.palette.common.white};
`

export const RightButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 20;

  padding: ${({ theme }) => theme.spacing(1.25)};
  color: ${({ theme }) => theme.palette.common.white};
`

export const NextPageIcon = styled(ArrowForwardIosIcon)`
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing(1)};

  width: 23px;
  height: 23px;
`

export const PrevPageIcon = styled(ArrowBackIosNewIcon)`
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing(1)};

  width: 23px;
  height: 23px;
`
