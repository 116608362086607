import { ReactNode } from 'react'
import { ListItemText } from '@mui/material'

import { ListIconSt, TimestampTitle, UncollapsedListItem } from './transaction-details.styled'

interface IProps {
  icon: ReactNode
  title: string
}

export const UncollapsedItem = ({ icon, title }: IProps) => {
  return (
    <UncollapsedListItem>
      <ListIconSt>{icon}</ListIconSt>
      <ListItemText>
        <TimestampTitle>{title}</TimestampTitle>
      </ListItemText>
    </UncollapsedListItem>
  )
}
