import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { authServie } from 'src/services'
import { QUERY_KEYS, STORAGE_KEYS } from 'src/constants'
import { useRefreshToken } from './use-refresh-token.hook'

export const useMe = () => {
  const { productId } = useParams()
  const { mutateAsync: updateAccessToken } = useRefreshToken()

  return useQuery(
    [QUERY_KEYS.GET_ME],
    async () => {
      const token = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
      if (!token) return null

      const resp = await authServie.getJourneyUser(token, productId)

      if (!resp) {
        const tokenData = await updateAccessToken()
        return authServie.getJourneyUser(tokenData?.accessToken, productId)
      }

      return resp
    },
    {
      refetchOnMount: true,
    },
  )
}
