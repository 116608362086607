import axios from 'axios'

import { HttpService } from './http.service'
import { IJourneyResponse } from 'src/types'

class JourneyService extends HttpService {
  constructor() {
    super(axios)
  }

  getProductJourney(productId: string, locale: string) {
    return this.get<IJourneyResponse>(`product/${productId}/journey/${locale}`)
  }
}

export const journeyService = new JourneyService()
