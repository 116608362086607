import { JourneyLogButton, JourneyStepCard } from 'src/components'
import { IStep } from 'src/types'

import {
  CardWrapper,
  ItemSt,
  LastStepIcon,
  LogCircle,
  LogWrapper,
  StepsList,
} from './journey-step.styled'
import { useMap } from 'src/contexts/journey-map.context'
import { MutableRefObject, useState } from 'react'

interface IProps {
  steps: IStep[]
  detailed?: boolean
  topRef?: MutableRefObject<HTMLSpanElement>
}

export const JourneySteps = (props: IProps) => {
  const {steps, detailed, topRef} = props
  const { setMapCenter } = useMap();
  const [isMobile, ] = useState<boolean>(window.innerWidth < 770)
  const length = steps.length - 1
  const handleScroll = () => {
    !isMobile && topRef.current.scrollIntoView({behavior: 'smooth'})
  };
  return (
    <StepsList>
      {steps.map((item, i) => (
        <ItemSt key={item.id} onClick={() => {
            setMapCenter(item.latitude / 10_000, item.longitude / 10_000, 5);
        }}>
          <LogWrapper>
            {length === i ? <LastStepIcon color="primary" /> : <LogCircle />}
            <JourneyLogButton
              step={item}
              isMobile={isMobile}
            />
          </LogWrapper>
          <CardWrapper isLastStep={length === i} onClick={handleScroll}>
            <JourneyStepCard
              email={item.email}
              account_approved={item.account_approved}
              isMobile={isMobile}
              mainMedia={detailed ? item.mainMedia : undefined}
              location_address={item.location_name}
              image={item.image}
              name={item.name}
              landing_page_show={item.landing_page_show}
              impacts={item.impacts}
              account_holder={item.account_holder}
              image_small={item.image_small || item.image_medium}
              media={detailed ? item.media : undefined}
              description={detailed ? item.description : undefined}
            />
          </CardWrapper>
        </ItemSt>
      ))}
    </StepsList>
  )
}
