import { ModalSt } from './transactions-modal.styled'
import { ModalInner } from './modal-inner.component'

interface IProps {
  stepLocation: string
  stepCode: string
  stepId: string
  open: boolean
  handleClose: () => void
}

export const TransactionsModal = (props: IProps) => {
  return (
    <ModalSt open={props.open} onClose={props.handleClose}>
      <ModalInner
        stepId={props.stepId}
        stepCode={props.stepCode}
        stepLocation={props.stepLocation}
      />
    </ModalSt>
  )
}
