import { useQuery } from '@tanstack/react-query'

import { journeyService } from 'src/services'
import { QUERY_KEYS } from 'src/constants'

export const useProductJourney = (productId: string, locale: string) => {
  return useQuery([QUERY_KEYS.GET_PRODUCT_JOURNEY, productId, locale], () =>
    journeyService.getProductJourney(productId, locale),
  )
}
