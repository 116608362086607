import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { JourneyCard, JourneyMap, Loader, PageWrapper } from 'src/components'
import { MapProvider } from 'src/contexts/journey-map.context'
import { useProductJourney } from 'src/hooks'

export default function JourneyPage() {
  const [, i18n] = useTranslation()
  const { productId } = useParams()
  const { data, isLoading } = useProductJourney(productId, i18n.language)
  const ref = useRef<HTMLSpanElement | null>(null);
  const topRef = useRef<HTMLSpanElement | null>(null);

  const journey = data?.product?.journey
  const steps = journey?.steps || []

  if (!steps || isLoading) {
    return <Loader />
  }

  return (
    <PageWrapper>
      <MapProvider>
        <span ref={topRef}/>
        <JourneyMap steps={steps} satelliteView={data?.product?.satellite_map} scrollToRef={ref}/>
        <span ref={ref}/>
        <JourneyCard steps={steps} journeyName={journey.journey_name} topRef={topRef}/>        
      </MapProvider>
    </PageWrapper>
  )
}
