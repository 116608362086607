import { useQuery } from '@tanstack/react-query'

import { impactService } from 'src/services'
import { QUERY_KEYS, STORAGE_KEYS } from 'src/constants'

export const useProductImpacts = (productId: string, locale: string) => {
  return useQuery([QUERY_KEYS.GET_PRODUCT_IMPACTS, productId, locale], () =>
    impactService.getProductImpacts(productId, locale, localStorage.getItem(STORAGE_KEYS.GUEST_ID)),
  )
}
