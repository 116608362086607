import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ITransaction } from 'src/types'

import { TransactionItem } from './transaction-item.component'
import { TransactionsListSt } from './transaction-details.styled'

interface IProps {
  stepLocation: string
  transactions?: ITransaction[]
}

export const TransactionsList = (props: IProps) => {
  const [t] = useTranslation()

  return (
    <TransactionsListSt>
      {props.transactions?.length === 0 && (
        <Typography variant="subtitle2" align="center">
          {t('no-tx-label')}
        </Typography>
      )}
      {props.transactions?.map((transaction, i) => (
        <TransactionItem
          key={transaction.id}
          longitude={transaction.scanner_longitude}
          latitude={transaction.scanner_latitude}
          timestamp={transaction.created_at}
          stepLocation={props.stepLocation}
          hash={transaction.account_tx}
          media={transaction.media}
          isLast={i === props.transactions.length - 1}
          scanner={transaction.scanner}
        />
      ))}
    </TransactionsListSt>
  )
}
