import { SwipeableDrawer, styled } from '@mui/material'

export const SwipeableDrawerSt = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    height: 100%;
    overflow-y: visible;
  }
`

export const CardSt = styled('div')<{ isOpen: boolean }>`
  position: absolute;
  top: -48lvh;
  left: 0;
  right: 0;

  height: 100%;
  width: 100%;

  border-radius: 15px 15px 0px 0px;
  background-color: ${({ theme }) => theme.palette.background.default};

  visibility: ${({ isOpen }) => (isOpen ? 'hidden' : 'visible')};
  opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  transition: opacity 0.3s ease-in-out ${({ isOpen }) => (isOpen ? '0.3s' : '0s')};
`

export const Line = styled('div')(
  ({ theme }) => `
  position: absolute;
  top: ${theme.spacing(1.25)};
  right: 50%;
  transform: translateX(50%);

  width: 50px;

  color: ${theme.palette.grey.A200};
  border: 2px solid ${theme.palette.grey.A200};
  border-radius: 50px;
`,
)

export const ContentWrapperClosed = styled('div')`
  height: 100%;
`

export const ContentWrapperOpen = styled('div')<{ isOpen: boolean }>`
  height: 100%;

  overflow-y: auto;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: opacity 0.3s ease-in-out ${({ isOpen }) => (isOpen ? '0s' : '0.3s')};
`
