import { useNavigate, useParams } from 'react-router-dom'

export const useNavigateWithParams = () => {
  const { productId, lng } = useParams()
  const navigate = useNavigate()

  return (route: string) => {
    navigate(`/${lng}/product/${productId}/${route}`, { replace: true })
  }
}
