import { Menu } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import { useTranslation } from 'react-i18next'
import { useSwiper } from 'swiper/react'

import { HeartIcon, HomeIcon, PinAddIcon, ProfileIcon } from 'src/assets/icons'
import { PopUpItem } from './pop-up-item.component'
import { PopUpLink } from './pop-up-link.component'

import { BASE_API_URL } from 'src/constants'
import { useLogout, useMe, useNavigateWithParams } from 'src/hooks'
import { convertToBase64Url } from 'src/utils'
import { PopUpUser } from './pop-up-user.component'
import { EType, useDrawerer } from 'src/contexts/modal-drawer.context'

interface IProps {
  open: boolean
  anchorEl: HTMLElement | null
  handleClose: () => void
}

export const MenuPopUp = ({ open, anchorEl, handleClose }: IProps) => {
  const [t] = useTranslation()
  const { data: me, isLoading } = useMe()
  const customNav = useNavigateWithParams()
  const swiper = useSwiper()
  const logOut = useLogout()
  const drawerer = useDrawerer()

  const handleNavigate = (to: string) => () => customNav(to)
  const handleJourney = () => {
    customNav('journey')
    drawerer.setActive(EType.JOURNEY)
    swiper.slideNext()
    handleClose()
  }
  const handleImpacts = () => {
    customNav('impacts')
    drawerer.setActive(null)
    swiper.slideTo(2)
    handleClose()
  }
  const handleLogout = async () => {
    await logOut()
    handleClose()
  }

  const from = convertToBase64Url(window.location.origin + window.location.pathname)

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClose}
    >
      <PopUpItem icon={<ArrowBackIcon />} onClick={handleClose} />
      <PopUpItem icon={<HomeIcon />} text={t('home-header-title')} onClick={handleNavigate('')} />
      <PopUpItem icon={<PinAddIcon />} text={t('journey-header-title')} onClick={handleJourney} />
      <PopUpItem icon={<HeartIcon />} text={t('impacts-header-title')} onClick={handleImpacts} />
      <PopUpItem
        icon={<ProfileIcon />}
        text={t('account-header-title')}
        onClick={handleNavigate('account')}
        divider
      />
      {me && !isLoading && (
        <>
          <PopUpUser title={me.name || me.email} image={me.image_small || me.image} />
          <PopUpItem
            icon={<LogoutIcon color="secondary" />}
            text={t('log-out-button-text')}
            onClick={handleLogout}
          />
        </>
      )}
      {!me && !isLoading && (
        <>
          <PopUpLink
            icon={<LoginIcon color="secondary" />}
            text={t('log-in-button-text')}
            link={`${BASE_API_URL}auth/journey/sign-in/${from}`}
          />
          <PopUpLink
            icon={<ProfileIcon />}
            text={t('sign-up-button-text')}
            link={`${BASE_API_URL}auth/journey/register/${from}`}
          />
        </>
      )}
    </Menu>
  )
}
