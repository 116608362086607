import axios from 'axios'

import { STORAGE_KEYS, BASE_API_V2_URL } from 'src/constants'

axios.defaults.baseURL = BASE_API_V2_URL

export const loadResources = async (lng: string) => {
  const resp = await axios.get(`locales/${lng}`, {
    params: {
      userToken: localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN),
    },
  })
  return resp.data
}
