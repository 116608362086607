export function getPolygonscanTxUrl(txHash: string) {
  return `${getBaseUrl()}/tx/${txHash}`
}

export function getPolygonscanAddressUrl(address: string) {
  return `${getBaseUrl()}/address/${address}`
}

function getBaseUrl() {
  const hostname = window.location.hostname
  const isProd = !hostname.includes('dev') && !hostname.includes('test')

  return `https://${isProd ? '' : 'mumbai.'}polygonscan.com`
}
