import { Avatar, Link, List, ListItem, ListItemIcon, Typography, styled } from '@mui/material'

export const TransactionsListSt = styled(List)`
  width: 100%;

  background-color: ${({ theme }) => theme.palette.background.paper};

  overflow-y: auto;
`

export const CollapsedListSt = styled(List)`
  margin-left: ${({ theme }) => theme.spacing(5)};
`

export const ListIconSt = styled(ListItemIcon)`
  margin-top: 0;

  min-width: 26px;
  width: unset;
`

export const CollapsedContent = styled('div')`
  width: 100%;
`

export const CollapsedTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1.25)};

  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`

export const ContentSubtitle = styled('div')`
  height: 100%;
  width: 100%;

  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;
`

export const ImageSt = styled(Avatar)`
  display: block;

  width: 100%;
  min-height: 128px;
`

export const HashSt = styled(Link)`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.38;

  display: flex;
  gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;
`

export const TimestampTitle = styled(Typography)`
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.35;

  height: 19px;
  white-space: wrap;
  word-break: break-word;
  overflow: hidden;
`

export const TimestampSub = styled(Typography)`
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.35;
`

export const UncollapsedListItem = styled(ListItem)`
  padding: 0;
  padding-top: 4px;
  padding-bottom: 4px;
`
