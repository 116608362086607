const hostname = window.location.hostname

// Journey landing page API URL
export const BASE_API_V2_URL =
  hostname === 'localhost'
    ? `https://api-dev.trackgood.io/v2/` // Dev API
    : `https://${hostname.replace('journey', 'api')}/v2/`

// Main application API URL
export const BASE_API_URL =
  hostname === 'localhost'
    ? 'https://app-api-dev.trackgood.io/api/' // Dev API
    : `https://${hostname.replace('journey', 'app-api')}/api/`

export const MAIN_APP_URL =
  hostname === 'localhost'
    ? 'https://app-dev.trackgood.io/' // Dev API
    : `https://${hostname.replace('journey', 'app')}/`
