import { Card, CardActionArea, Divider, Typography } from '@mui/material'

import { ImageCarousel, MediaList, StepInfo } from 'src/components'
import { IImpact, IMedia } from 'src/types'

import { ImpactsList } from './impacts-list.component'
import {
  CardChip,
  CardContentSt,
  CarouselWrapper,
  InfoWrapper,
  LowerContentSt,
  UpperContentSt,
} from './journey-step-card.styled'
import { useState } from 'react'

interface IProps {
  name: string
  location_address: string
  image_small: string
  image: string
  impacts: IImpact[]
  media?: IMedia[]
  mainMedia?: IMedia[]
  description?: string
  isMobile?: boolean
  landing_page_show?: boolean
  account_holder?: string
  account_approved?: boolean
  email?: string
}

export const JourneyStepCard = (props: IProps) => {
  const [showDescr, setShowDescr] = useState<boolean>(false)

  return (
    <Card>
      <CardActionArea
        disableTouchRipple={!!props.description}
        disableRipple={!!props.description}
      >
        <CardContentSt>
          <UpperContentSt>
            <CardChip label={props.name}/>
            <InfoWrapper>
              <StepInfo 
                email={props.email}
                account_approved={props.account_approved}
                name={props.name} 
                image={props.image_small} 
                location_name={props.location_address} 
                accImg={props.image} 
                landing_page_show={props.landing_page_show} 
                account_holder={props.account_holder}
              />
              <ImpactsList impacts={props.impacts} setShowDescr={setShowDescr} isMobile={props.isMobile}/>
            </InfoWrapper>
          </UpperContentSt>
          {props.mainMedia && (
            <CarouselWrapper>
              <ImageCarousel images={props.mainMedia} />
            </CarouselWrapper>
          )}
          {(showDescr || !props.isMobile) &&
          <>          
            <Divider />
            <LowerContentSt>
              {props.media && <MediaList media={props.media} />}
              {props.description && <Typography variant="body1">{props.description}</Typography>}
            </LowerContentSt>
          </>}
        </CardContentSt>
      </CardActionArea>
    </Card>
  )
}
